import React from 'react'

function Rejectreason(props) {
    return (
        <>
            {props.status == "3" && <div style={{ color: "red" }}>
               Status:- {props.status == "3"?"Rejected":"Verified"}<br/>
               Reason:- {props.reason}
            </div>}
            {props.status == "1" && <div style={{ color: "red" }}>
                <h6>Waiting for admin to verify</h6>
            </div>}
        </>
    )
}

export default Rejectreason