import React, { Component } from "react";
import axios from "axios";
import DocumentMeta from "react-document-meta";
import { baseUrl } from "../../Common/BaseUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { EmailValidation } from "../../Validation/EmailValidation";
import { MobileValidation } from "../../Validation/MobileValidation";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import { meta } from "../../Common/Meatdata";
import { ProductName } from "../../Common/ProductName";
import GoogleLoginButton from "../../Common/GoogleLogin"; // Adjust the import path as needed

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "password",
      type1: "password",
      textForCode: "Get Code",
      counter: 0,
      timerText: "",
      check1: "0",
      countryCode: "+91",
      PasswordIssue: "",
      codeMatchedStatus: false,
      buttonDisableVerification: true,
      buttonDisableCheck: false,
      selectedFiledType: "email",
      pair: "",
      disableRegister: false,
      automateCountryCode: "ae",
      inputMobile: "",
    };
  }

  getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    this.setState({
      automateCountryCode: data.country_code
        ? data.country_code.toLowerCase()
        : "ae",
    });
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };
  onBlurVerificationCode = (event) => {
    const { value } = event.target;
    if (!value) {
      this.setState({ verificationError: "This field is required" });
    }
  };

  referCode = () => {
    const registerData = {
      email: this.state.email,
    };
    axios
      .post(baseUrl + "/frontapi/generateReferalCode", registerData)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  startCountdown(seconds) {
    let counter = seconds;
    var interval = setInterval(() => {
      this.setState({ counter: counter });
      this.setState({ btnDisable: true, btnDisabled: true });
      this.setState({ timerText: "Resend In" });
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        this.setState({ textForCode: "Resend Code" });
        this.setState({ btnDisable: false, btnDisabled: false });
        this.setState({ timerText: "" });
      }
    }, 1000);
  }

  getCode = async (event) => {
    event.preventDefault();
    if (this.state.selectedFiledType === "email") {
      if (!this.state.email) {
        this.setState({ emailError: "This field is required" });
        return false;
      }
      if (
        !/^[A-Z-z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
      ) {
        this.setState({ emailError: "Please enter a valid email address" });
        return false;
      }
      if (this.state.emailError) {
        return false;
      }
    }
    if (this.state.selectedFiledType === "mobile") {
      if (!this.state.mobile) {
        this.setState({ mobileError: "This field is required" });
        return false;
      }

      if (this.state.mobileError) {
        return false;
      }
    }

    const registerData = {
      countryCode: this.state.countryCode,
      page: "register",
      selectedFiledType: this.state.selectedFiledType,
    };
    if (this.state.selectedFiledType === "mobile") {
      registerData.mobile = this.state.mobile;

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(this.state.countryCode)) {
        this.setState({ mobileError: "Please enter a valid country code" });
        return;
      }

      const isValidLength =
        this.state.mobile.length >= 5 && this.state.mobile.length <= 15;
      if (!isValidLength) {
        this.setState({ mobileError: "Please enter a valid mobile number" });
        return false;
      } else {
        this.setState({ mobileError: "" });
      }
    }
    if (this.state.selectedFiledType === "email") {
      registerData.email = this.state.email;
    }
    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((res) => {
        var resp = res.data;
        this.setState({ verificationError: "", EmailMessageErr: resp.message });
        if (resp.status === true) {
          this.startCountdown(30);
          toast.dismiss();
          toast.success(resp.message);
          this.setState({ buttonDisableVerification: false });
        }
        if (resp.status === false) {
          this.setState({ buttonDisableVerification: true });
          toast.error(resp.message);
          return false;
        }
      });
  };

  handleChange1 = (event) => {
    var messageCheck1 = "";
    if (event.target.checked == false) {
      messageCheck1 = "Please select terms & conditions";
      this.setState({
        check1: "0",
        messageCheck1: messageCheck1,
        buttonDisableCheck: false,
      });
    }
    if (event.target.checked == true) {
      this.setState({
        buttonDisableCheck: true,
        check1: "1",
        messageCheck1: messageCheck1,
      });
    }
  };

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  handleClick1 = () =>
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));

  static displayName = "RememberMe";

  state = {
    email: "",
    password: "",
    isChecked: false,
  };
  componentWillMount() {
    meta.title = `Signup | ${ProductName} Exchange`;
    meta.description = `Signup in ${ProductName} Exchange to enjoy its features.`;
  }

  componentDidMount() {
    this.getData();
    this.getOpenPair();
    if (localStorage.checkbox && localStorage.email !== "") {
      this.setState({
        isChecked: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
    if (this.props.referId) {
      this.setState({ ReferId: this.props.referId });
    }
  }
  handlechange = (event) => {
    let { name, value } = event.target;
    if (name === "VerificationCode") {
      this.setState({ verificationError: "" });
    }
    this.setState({ [name]: value, errMsg: "" });
  };

  handlechangeCheck = (event) => {
    var check1 = event.target.checked;
    var messageCheck1 = "";
    if (!check1) {
      messageCheck1 = "Terms checked required";
    }
    this.setState({
      check1: check1,
      messageCheck1: messageCheck1,
    });
  };

  handleChange101 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
    if (name === "mobile") {
      if (this.state.textForCode === "Resend Code") {
        this.setState({ textForCode: "Get Code" });
        this.setState({ VerificationCode: "" });
      }
    }
    if (name === "email") {
      const emailvalidation = EmailValidation(name, value);
      this.setState({ emailError: emailvalidation });
    }
    if (name === "mobile") {
      const mobilevalidation = MobileValidation(name, value, "Register");
      this.setState({ mobileError: mobilevalidation });
    }
    if (name === "verification_code") {
      this.setState({
        verificationError: "This field is required",
      });
    }
    if (name === "Password") {
      var passwordError = PasswordValidation(name, value);
      this.setState({
        PasswordIssue: passwordError,
      });
      if (passwordError === "mismatched") {
        this.setState({
          CPasswordIssue: "Confirm password does not match",
          PasswordIssue: "",
        });
      }
      if (passwordError === "") {
        this.setState({
          CPasswordIssue: "",
          PasswordIssue: "",
        });
      }
    }

    if (name === "CPassword") {
      var CpasswordError = PasswordValidation(name, value);
      this.setState({
        CPasswordIssue: CpasswordError,
      });
      if (passwordError === "") {
        this.setState({
          CPasswordIssue: "",
        });
      }
    }
  };

  cancelCourse = () => {
    this.setState({
      username: "",
      email: "",
      VerificationCode: "",
      Password: "",
      CPassword: "",
      ReferId: "",
      country: "",
    });
  };

  handleBlurChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "email" && !value) {
      this.setState({
        EmailIssue: "This field is required",
      });
    }
    if (name === "Password" && !value) {
      this.setState({
        PasswordIssue: "This field is required",
      });
    }
    if (name === "CPassword" && !value) {
      this.setState({
        CPasswordIssue: "This field is required",
      });
    }
  };

  handleMobileSubmit = (e) => {
    e.preventDefault();
    let data = {
      countryCode: this.state.countryCode,
      verification_code: this.state.VerificationCode,
      selectedFiledType: this.state.selectedFiledType,
    };
    if (this.state.selectedFiledType === "mobile") {
      if (!this.state.countryCode || this.state.countryCode.length < 2) {
        this.setState({ mobileError: "Country code is required" });
        return false;
      }

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(this.state.countryCode)) {
        this.setState({ mobileError: "Please enter a valid country code" });
        return;
      }

      if (!this.state.mobile) {
        this.setState({ mobileError: "This field is required" });
        return false;
      }

      if (this.state.mobile.length < 5 || this.state.mobile.length > 15) {
        this.setState({
          mobileError: "Mobile number shoud be between 5 to 15 characters",
        });
        return false;
      }

      data.mobile = this.state.mobile;
    } else {
      if (!this.state.email) {
        this.setState({ emailError: "This field is required" });
        return false;
      }
      const emailvalidation = EmailValidation("email", this.state.email);
      this.setState({ emailError: emailvalidation });
      data.email = this.state.email;
    }
    if (!this.state.VerificationCode) {
      this.setState({ verificationError: "This field is required" });
      return false;
    }

    axios.post(baseUrl + "/frontapi/codeVerify", data).then((res) => {
      var resp = res.data;
      if (resp.status) {
        this.setState({
          btnDisabled: true,
          disableRegister: true,
        });
        toast.success(resp.message);
      } else {
        toast.dismiss();
        toast.error(resp.message);
      }
      this.setState({
        codeMatchedStatus: resp.status,
      });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let registerData = {
      countryCode: this.state.countryCode,
      VerificationCode: this.state.VerificationCode,
      Password: this.state.Password,
      CPassword: this.state.CPassword,
      ReferId: this.state.ReferId,
      terms: this.state.check1,
      selectedFiledType: this.state.selectedFiledType,
    };
    if (this.state.mobile) {
      registerData.mobile = this.state.mobile;
    }
    if (this.state.email) {
      registerData.email = this.state.email.toLowerCase();
    }
    var passwordError = PasswordValidation("Password", this.state.Password);
    this.setState({
      PasswordIssue: passwordError,
    });

    if (passwordError === "mismatched") {
      this.setState({
        CPasswordIssue: "Confirm password does not match",
        PasswordIssue: "",
      });

      if (passwordError === "") {
        this.setState({
          CPasswordIssue: "",
          PasswordIssue: "",
        });
      }
    }
    var CpasswordError = PasswordValidation("CPassword", this.state.CPassword);
    this.setState({
      CPasswordIssue: CpasswordError,
    });
    if (passwordError || CpasswordError) {
      return false;
    }

    if (this.state.check1 === "0" || !this.state.check1) {
      this.setState({ messageCheck1: "Please select terms & conditions" });
      return false;
    }
    axios.post(baseUrl + "/frontapi/register", registerData).then((res) => {
      var resp = res.data;
      localStorage.setItem("registerMessage", this.state.messageR);
      if (resp.status === true) {
        this.setState({
          StatusMessage: resp.message,
          EmailErr: resp.EmailErr,
          Emailmessage: resp.Emailmessage,
          respStatus: resp.status,
          ExistEmail: resp.EmailMessage1,
          mobile: "",
          email: "",
        });
        this.referCode();
        this.cancelCourse();
        toast.success(
          "Congratulations, your account has been registered successfully"
        );
        setTimeout(() => {
          window.location = "/login-to-buy-sell-crypto";
        }, 2000);
        return false;
      }
      if (resp.status === false) {
        toast.error(resp.message);
        return false;
      }
    });
  };

  handleOnChange = (value, country) => {
    const effectiveValue = value.replace(/[\s\+]/g, "");
    const isEffectivelyEmpty = effectiveValue === country.dialCode;
    if (isEffectivelyEmpty) {
      this.setState({
        selectedFiledType: "email",
        // countryCode: "",
        mobile: "",
        emailError: "This filed is required",
      });
    } else {
      this.setState({ selectedFiledType: "mobile", emailError: "" });
    }
    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    this.setState({
      // countryCode: "+" + country.dialCode,
      mobileError: "",
      textForCode: "Get Code",
      mobile: adjustedMobile,
      email: "",
    });
  };

  handleChangeMobile = (event) => {
    const { name, value } = event.target;

    this.setState({
      emailError: "",
      email: "",
    });

    if (name === "phone") {
      this.setState({
        mobile: value,
      });

      if (!this.state.countryCode) {
        this.setState({
          mobileError: "Country code is required",
        });
        return;
      }

      if (!value) {
        this.setState({
          mobileError: "This field is required",
        });

        return;
      }

      // Mobile number validation using regex for Indian numbers
      const mobileRegex = /^[6-9]\d{9}$/;
      if (!mobileRegex.test(value)) {
        this.setState({
          mobileError: "Please enter a valid 10-digit mobile number",
        });
        return;
      }

      if (value.length < 5 || value.length > 15) {
        this.setState({
          mobileError: "Mobile number shoud be between 5 to 15 characters",
        });

        return;
      }

      this.setState({
        emailError: "",
        mobileError: "",
      });

      if (this.state.textForCode === "Resend Code") {
        this.setState({
          textForCode: "Get Code",
        });
      }
    }

    if (name === "countryCode") {
      if (!value) {
        this.setState({
          countryCode: "+",
          mobileError: "Country code is required",
        });
        return;
      }

      this.setState({
        countryCode: value,
      });

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(value)) {
        this.setState({ mobileError: "Please enter a valid country code" });
        return;
      }

      this.setState({
        mobileError: "",
      });
    }
  };

  handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      this.setState({ textForCode: "Get Code" });
      if (isMobile) {
        this.setState({
          selectedFiledType: "mobile",
          inputMobile: value,
          mobile: value,
        });
      } else if (isEmail) {
        this.setState({ selectedFiledType: "email" });
      }
      this.setState({ email: value });

      const emailvalidation = EmailValidation(name, value);
      this.setState({ emailError: emailvalidation });
    }
  };

  getOpenPair = async () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            pair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => {});
  };

  switchButton = (value) => {
    this.setState({
      mobile: "",
      email: "",
      countryCode: "+91",
      emailError: "",
      mobileError: "",
    });

    if (value === "email") {
      this.setState({
        selectedFiledType: "email",
      });
    }

    if (value === "mobile") {
      this.setState({
        selectedFiledType: "mobile",
      });
    }
  };

  render() {
    return (
      <DocumentMeta {...meta}>
        <div className="">
          <div className="register-main-outer signup-main-outer position-relative ">
            <span className="main-bg fadeInRight animated"></span>
            <div className="register-inner signup-inner">
              <div className="container">
                <div className="row m-0 ">
                  <div className="col-md-5 col-12 p-0 m-auto">
                    <div className="register-right fadeInLeft animated ">
                      <div className="heading-link-outer">
                        <div className="heading d-flex mb-2 align-items-center">
                          <h3 className=" ">Sign Up</h3>
                          <div className="login_tab d-inline-flex ml-auto">
                            <a
                              className={
                                this.state.selectedFiledType === "email"
                                  ? "active"
                                  : ""
                              }
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.switchButton("email");
                              }}
                            >
                              {" "}
                              Email
                            </a>
                            <a
                              className={
                                this.state.selectedFiledType === "mobile"
                                  ? "active"
                                  : ""
                              }
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.switchButton("mobile");
                              }}
                            >
                              {" "}
                              Mobile
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="register-form signup-form">
                        <form
                          onSubmit={this.handleMobileSubmit}
                          ref={(el) => (this.myFormRef = el)}
                        >
                          <div className="form-group position-relative">
                            {this.state.selectedFiledType === "mobile" ? (
                              <>
                                <label className="form-label">
                                  Mobile Number
                                </label>

                                <div className="row">
                                  <div className="col-3">
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      placeholder="Country Code"
                                      name="countryCode"
                                      autoFocus
                                      disabled
                                      value={this.state.countryCode || "+"}
                                      onChange={this.handleChangeMobile}
                                    />
                                  </div>

                                  <div className="col-9 pl-0">
                                    <input
                                      className="form-control"
                                      type="number"
                                      autoComplete="off"
                                      placeholder="Enter Mobile Number"
                                      name="phone"
                                      autoFocus
                                      value={this.state.mobile}
                                      onChange={this.handleChangeMobile}
                                      disabled={
                                        this.state.counter == 0 ? false : true
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <label className="form-label">Email</label>
                                <input
                                  className="form-control"
                                  autoComplete="off"
                                  autoFocus
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Enter Email Address"
                                  value={this.state.email}
                                  onChange={this.handleChangeEmail}
                                  // onPaste={(e) => e.preventDefault()}
                                  onBlur={this.handleBlurChange}
                                  disabled={
                                    this.state.btnDisabled ||
                                    this.state.disableRegister
                                  }
                                />
                              </>
                            )}
                            <div style={{ color: "red" }}>
                              {this.state.selectedFiledType === "mobile"
                                ? this.state.mobileError
                                : this.state.emailError}
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Verification Code
                            </label>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Verification Code"
                                name="VerificationCode"
                                onChange={this.handlechange}
                                disabled={
                                  this.state.codeMatchedStatus ||
                                  this.state.buttonDisableVerification
                                }
                                onBlur={this.onBlurVerificationCode.bind(this)}
                                value={this.state.VerificationCode}
                                aria-label="Input group example"
                                aria-describedby="btnGroupAddon"
                              />

                              {!this.state.codeMatchedStatus && (
                                <>
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn_man"
                                      id="btnGroupAddon"
                                      onClick={this.getCode}
                                      disabled={
                                        this.state.codeMatchedStatus ||
                                        this.state.btnDisable
                                      }
                                    >
                                      {this.state.timerText}
                                      {"  "}
                                      {this.state.counter == 0
                                        ? this.state.textForCode
                                        : this.state.counter}
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                            <span style={{ color: "red" }}>
                              {this.state.verificationError}
                            </span>
                          </div>

                          {!this.state.codeMatchedStatus && (
                            <div className="form-group mx-auto submit-btn">
                              <button className="btn w100 btn_man">
                                Submit
                              </button>
                            </div>
                          )}
                        </form>
                        {this.state.codeMatchedStatus && (
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {" "}
                                    Password
                                  </label>
                                  <div className="eye_pass">
                                    <input
                                      className="form-control"
                                      type={this.state.type}
                                      name="Password"
                                      id="Password"
                                      placeholder="Enter Password"
                                      onBlur={this.handleBlurChange}
                                      value={this.state.Password}
                                      maxLength="20"
                                      onChange={this.handleChange101}
                                    />
                                    <span
                                      className="password__show eye1"
                                      onClick={this.handleClick}
                                    >
                                      {this.state.type === "text" ? (
                                        <i className="fa fa-eye"></i>
                                      ) : (
                                        <i className="fa fa-low-vision"></i>
                                      )}
                                    </span>
                                    {this.state.PasswordIssue && (
                                      <div
                                        className="erar_class"
                                        style={{ color: "red" }}
                                      >
                                        {this.state.PasswordIssue}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-12 pl-md-0">
                                <label className="form-label">
                                  Confirm Password
                                </label>
                                <div className="form-group eye_pass">
                                  <input
                                    className="form-control"
                                    type={this.state.type1}
                                    placeholder="Enter Confirm Password"
                                    onBlur={this.handleBlurChange}
                                    name="CPassword"
                                    maxLength="20"
                                    onChange={this.handleChange101}
                                    value={this.state.CPassword}
                                  />
                                  <span
                                    className="password__show eye1"
                                    onClick={this.handleClick1}
                                  >
                                    {this.state.type1 === "text" ? (
                                      <i className="fa fa-eye"></i>
                                    ) : (
                                      <i className="fa fa-low-vision"></i>
                                    )}
                                  </span>

                                  {this.state.CPasswordIssue && (
                                    <div
                                      className="erar_class"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.CPasswordIssue}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Referral Code(Optional) </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Referral Code (Optional)"
                                name="ReferId"
                                onChange={this.handlechange}
                                value={this.state.ReferId}
                              />
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox text-left">
                                <input
                                  className="custom-control-input cursor_pointer"
                                  id="rememberPasswordCheck"
                                  type="checkbox"
                                  name="check1"
                                  onChange={this.handleChange1}
                                  value={this.state.check1}
                                />
                                <label
                                  className="custom-control-label rember"
                                  htmlFor="rememberPasswordCheck"
                                >
                                  I agree to the{" "}
                                  <a
                                    href="terms-&-conditions-of-crypto-exchange"
                                    target="_blank"
                                    className="a_color"
                                  >
                                    Terms & Conditions
                                  </a>{" "}
                                  <span className="regStart">*</span>{" "}
                                </label>
                              </div>
                              <span style={{ color: "red" }}>
                                {this.state.messageCheck1}
                              </span>
                            </div>
                            <div className="form-group">
                              <button className="btn w100 btn_man">
                                SIGN UP
                              </button>
                            </div>
                          </form>
                        )}

                        <div className="form-group text-center">
                          <span>Already have an account </span>
                          <Link to="/login-to-buy-sell-crypto" className="">
                            Login
                          </Link>
                        </div>
                        {/* <div className="form-group text-center">OR</div>
                        <div className="form-group log-btn mx-auto">
                          <GoogleLoginButton
                            baseUrl={baseUrl}
                            redirectUrl={`/exchange/${this.state.pair}`}
                          ></GoogleLoginButton>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}
export default Register;
