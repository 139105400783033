import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import SelfieClick from "../SettingsFile/SelfieClick";
import { ImageValidation } from "../Validation/ImageValidation";
import { SelfieApi } from "./Apis";
import Rejectreason from "./Rejectreason";
const baseUrl = myConstList.baseUrl;

const Face = (props) => {
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [selfie, setSelfie] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selfieError, setSelfieError] = useState("");
  const [useruploadSelfieImage, setUseruploadSelfieImage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [selfieStatus, setSelfieStatus] = useState("0");
  const [imageType, setImageType] = useState("upload");
  const [rejectionReason, setRejectionReason] = useState("");
  const [selfieImageError, setSelfieImageError] = useState("");
  const [BtnDisable4, setBtnDisable4] = useState(false);

  const [selfieImagesDisabled, setselfieImagesDisabled] = useState(false);
  const [invalidImage1, setinvalidImage1] = useState("");
  const [selfiie, setselfiie] = useState("");

  const [selfiopen, setselfiopen] = useState(false);
  const [selfibtn, setselfibtn] = useState(false);
  const [state, setState] = useState({
    selfieClicedButtonByUser: "",
    IdentityPic1: "",
    userUploadImage1: "",
    selfieImageError: "",
    reUploadSelfie: 0,
  });

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setCountryCode(props.userinfo[0].country_code);
      setSelfieStatus(props.userinfo[0].selfie_status);
      props.userinfo[0].selfie_image &&
        setImage(
          baseUrl + "/static/upload/selfie/" + props.userinfo[0].selfie_image
        );
      setRejectionReason(props.userinfo[0].d4_rejectResion);
    }
  }, [props]);

  const handleChange = (e) => {
    let name = e.target.name;
    if (name == "selfie") {
      let error = ImageValidation("Selfie", e.target.files[0]);
      setSelfieError(error);
      setSelfie(e.target.files[0]);
      setImageType("upload");
      e.target.files[0]
        ? setUseruploadSelfieImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadSelfieImage("");
    }
  };

  const handleSubmit = async () => {
    if (!selfie) {
      setSelfieError("This field is required");
      return false;
    }
    if (selfieError) {
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var formdata = new FormData();
    formdata.append("IdentityProof1", selfie);
    // }
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    let faceMatch = await SelfieApi(formdata, config);
    setBtnDisable(false);
    props.resendResult(true);
    if (faceMatch.status) {
      toast.success(faceMatch.message);
    } else {
      toast.error(faceMatch.message);
    }
  };
  const handleSelfieChange = (e) => {
    setState({
      selfieClicedButtonByUser: e,
      IdentityPic1: "",
      userUploadImage1: "",
      selfieImageError: "",
      reUploadSelfie: 0,
    });
    setselfiopen(!selfiopen);
    setImage(e);
    setUseruploadSelfieImage(e);
    setselfiie(e);
    setselfibtn(true);
  };
  function IdentitySubmit1(event) {
    event.preventDefault();

    if (!state.selfieClicedButtonByUser) {
      toast.error("Please select/upload image first");
      state.selfieImageError = "Please upload selfie image first";
      return false;
    }

    if (selfieImageError) {
      setSelfieImageError(selfieImageError);

      return false;
    }

    var IdentityPic1 = state.IdentityPic1;
    setBtnDisable4(true);

    const formData = new FormData();
    var selfieData;
    var selfieUrl;

    if (IdentityPic1) {
      formData.append("IdentityProof1", state.IdentityPic1);
      selfieUrl = "/frontapi/selfie";
    } else {
      selfieData = {
        IdentityProof1: state.selfieClicedButtonByUser,
      };
      selfieUrl = "/frontapi/cameraSelfie";
    }

    const config = {
      headers: {
        // "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    setselfieImagesDisabled(true);
    setTimeout(() => setselfieImagesDisabled(false), 1000);

    axios
      .post(baseUrl + selfieUrl, IdentityPic1 ? formData : selfieData, config)
      .then((resp) => {
        var resp1 = resp.data;

        setinvalidImage1(resp1.message);
        setBtnDisable4(false);
        if (resp1.status === true) {
          props.resendResult(true);

          toast.success("Image uploaded successfully");
          window.location.reload();
        } else {
          toast.error(resp1.message);
        }
      });
  }

  const handleSelfiopen = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator &&
        navigator.mediaDevices
          .getUserMedia({ video: { width: 300 } })
          .then((stream) => {
            setselfiopen(!selfiopen);
            setselfibtn(!selfibtn);
            setSelfieError("");
          })
          .catch((err) => {
            console.error("error:", err);
            setSelfieError("No camera detected");
            return;
          });
    } else {
      setSelfieError("No camera detected");
    }
    return;
  };
  return (
    <>
      <div className="face-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>

          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">Face Verification</h6>
              <div className="row">
                <div className="col-md-6 col-12">
                  <b className="mt-0">Requirements</b>
                  <ul className="dit_ul mb-4">
                    <li>
                      Photos must be in PNG or JPEG (.jpg .jpeg .jpe .jfif .jif)
                      format.
                    </li>
                    <li>
                      Photos must be clear and high-resolution, with all
                      information clearly visible.
                    </li>
                    <li>
                      Photos and documents must not be edited or manipulated.
                    </li>
                    <li>Color photos only.</li>
                    <li>Documents presented must be original (not copies)</li>
                    <li>No watermark on the photos</li>
                    <li>
                      The ID must be valid (for example, passports that have
                      expired will not be accepted).
                    </li>
                    <li>Your face must be clearly visible.</li>
                    <li>Only one person per photo.</li>
                    <li>
                      The handwritten note must say “Rubiex” and the current
                      date.
                    </li>
                  </ul>
                </div>
                <div className="form-group col-md-6 col-12">
                  {!selfiopen ? (
                    <>
                      <div id="file-upload-form" className="uploader mt-3">
                        <input
                          type="file"
                          id="selfie"
                          name="selfie"
                          onChange={handleChange}
                          disabled={
                            selfieStatus !== "0" && selfieStatus !== "3"
                              ? true
                              : false
                          }
                        />
                        <label for="selfie" id="file-drag">
                          <img
                            src={
                              useruploadSelfieImage !== ""
                                ? useruploadSelfieImage
                                : image
                            }
                            className="img-fluid22"
                            alt="Identity"
                          />
                          <div id="start">
                            <span className="fasi"></span>
                            <div>Upload Face Image</div>
                            <div id="notimage" className="hidden">
                              Please select an image
                            </div>
                          </div>

                          <div id="response" className="hidden">
                            <div id="messages"></div>
                            <progress
                              className="progress"
                              id="file-progress"
                              value="0"
                            >
                              <span>0</span>%
                            </progress>
                          </div>
                        </label>
                      </div>
                      <p className="mt-0">
                        *only jpg, jpeg ,jpe ,jfif ,jif files supported
                      </p>
                    </>
                  ) : (
                    <>
                      <SelfieClick
                        fun={handleSelfieChange}
                        selfie_status={selfieStatus}
                        selfiie={selfiie}
                      />
                    </>
                  )}

                  {selfieError && (
                    <div className="text-danger">
                      <p>{selfieError}</p>
                    </div>
                  )}

                  {selfieStatus == 1 || selfieStatus == 2 ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={handleSelfiopen}
                    >
                      {!selfiopen ? "Selfie" : "Face"}
                    </button>
                  )}

                  <Rejectreason
                    reason={rejectionReason}
                    status={selfieStatus}
                  />
                </div>
              </div>
              {selfieStatus !== "2" && (
                <button
                  className="btn btn_man w100px"
                  onClick={selfibtn ? IdentitySubmit1 : handleSubmit}
                  disabled={
                    btnDisable || (selfieStatus !== "0" && selfieStatus !== "3")
                  }
                >
                  {btnDisable || (selfieStatus !== "0" && selfieStatus !== "3")
                    ? "Submited"
                    : "Submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Face;
