import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { BankApi } from "./Apis";

const Bank = (props) => {
  const [bankName, setBankName] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [reenterAccountNumber, setReenterAccountNumber] = useState("");
  const [reenterAccountErr, setReenterAccountErr] = useState("");
  const [accHolderName, setAccHolderName] = useState("");
  const [accHolderNameError, setAccHolderNameError] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchNameErr, setBranchNameErr] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accNumberError, setAccNumberError] = useState("");
  const [bankStatus, setBankStatus] = useState("0");
  const [btnDisable, setBtnDisable] = useState(false);
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setAccHolderName(props.userinfo[0].AccHolder);
      setBankStatus(props.userinfo[0].bank_status);
      setAccNumber(props.userinfo[0].AccNum);
      setIfsc(props.userinfo[0].Ifsc);
      setCountry(props.userinfo[0].country_code);
      setBranchName(props.userinfo[0].BranchName);
      setBankName(props.userinfo[0].BankName);
      setReenterAccountNumber(props.userinfo[0].AccNum);
    }
  }, [props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bankName") {
      setBankName(value);
      if (!value) {
        setBankNameError("This field is required");
        return false;
      }
      setBankNameError("");
    }
    if (name === "ifsc") {
      setIfsc(value);
      if (!value) {
        setIfscCodeError("This field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length === 0) {
        setIfscCodeError("Please enter a valid ifsc code");
        return false;
      }
      if (!value.match("^[A-Z]{4}0[A-Z0-9]{6}$")) {
        setIfscCodeError("Please enter a valid ifsc code");
        return false;
      }

      setIfscCodeError("");
    }
    if (name === "accNumber") {
      setAccNumber(value);
      if (!value) {
        setAccNumberError("This field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        setAccNumberError("Please enter a valid account number");
        return false;
      }
      if (!value.match(/^[0-9]{6,20}$/)) {
        setAccNumberError("Please enter a valid account number");
        return false;
      }
      if (!(value.length >= 5 && value.length <= 20)) {
        setAccNumberError("Please enter a valid account number");
        return false;
      }
      setAccNumberError("");
    }
    if (name === "accHolderName") {
      setAccHolderName(value);
      if (!value) {
        setAccHolderNameError("This field is required");
        return false;
      }
      if (value.replace(/\s+/g, "").length == 0) {
        setAccHolderNameError("Please enter a valid account number");
        return false;
      }
      setAccHolderNameError("");
    }
    if (name === "reenterAccountNumber") {
      setReenterAccountNumber(value);
      if (!value) {
        setReenterAccountErr("This field is required");
        return false;
      }
      if (accNumber) {
        if (value !== accNumber) {
          setReenterAccountErr("Account number not matching");
          return false;
        }
      }
      setReenterAccountErr("");
    }

    if (name === "branchName") {
      setBranchName(value);
      if (!value) {
        setBranchNameErr("This field is required");
        return false;
      }
      setBranchNameErr("");
    }
  };

  const handleSubmit = async () => {
    // console.log(
    //   "accNumberError",
    //   accNumberError,
    //   "ifscCodeError",
    //   ifscCodeError,
    //   "accHolderNameError",
    //   accHolderNameError,
    //   "reenterAccountErr",
    //   reenterAccountErr,
    //   "bankNameError",
    //   bankNameError,
    //   "branchNameErr",
    //   branchNameErr
    // );
    if (
      accNumberError ||
      ifscCodeError ||
      reenterAccountErr ||
      bankNameError ||
      branchNameErr
    ) {
      return false;
    }
    if (!bankName) {
      setBankNameError("This field is required");
      return false;
    }
    // if (!accHolderName) {
    //   setAccHolderNameError("This field is required");
    //   return false;
    // }
    // if (accHolderName.replace(/\s+/g, "").length == 0) {
    //   setAccHolderNameError("Please enter a valid name");
    //   return false;
    // }
    if (!accNumber) {
      setAccNumberError("This field is required");
      return false;
    }
    if (!(accNumber.length >= 5 && accNumber.length <= 20)) {
      setAccNumberError("Please enter a valid account number");
      return false;
    }
    if (!reenterAccountNumber) {
      setReenterAccountErr("This field is required");
      return false;
    }
    if (accNumber) {
      if (reenterAccountNumber !== accNumber) {
        setReenterAccountErr("Account number not matching");
        return false;
      }
    }
    if (!ifsc) {
      setIfscCodeError("This field is required");
      return false;
    }

    if (ifsc.replace(/\s+/g, "").length == 0) {
      setIfscCodeError("Please enter a valid ifsc code");
      return false;
    }
    if (!branchName) {
      setBranchNameErr("This field is required");
      return false;
    }

    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let data = {
      Ifsc: ifsc,
      AccNum: accNumber,
      //   AccName: accHolderName,
      reenterAccountNumber: reenterAccountNumber,
      bankName: bankName,
      branchName: branchName,
    };
    const result = await BankApi(data, config);
    setBtnDisable(false);
    if (result.status) {
    //   props.resendResult(true);
      toast.success("Bank details updated successfully");
    } else {
    //   props.resendResult(true);
      toast.error(result.message);
    }
  };

  return (
    <>
      <div className="col-12">
        <div className="white_box">
          <h6 className="position-relative">Bank Verification</h6>
          <b className="mt-0">Requirements</b>
          <ul className="dit_ul mb-4">
            <li>
              The document must be valid at least 3 months after the
              verification submission date.
            </li>
          </ul>
          <div className="row">
            <div className="form-group col-lg-4 col-md-6">
              <label>Bank name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Bank name"
                name="bankName"
                value={bankName}
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[a-zA-Z ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={bankStatus !== "0" ? true : false}
              />
              {bankNameError && (
                <div className="text-danger">{bankNameError}</div>
              )}
            </div>

            <div className="form-group col-lg-4 col-md-6">
              <label>Bank A/C number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Bank A/C number"
                name="accNumber"
                value={accNumber}
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={bankStatus !== "0" ? true : false}
              />
              {accNumberError && (
                <div className="text-danger">{accNumberError}</div>
              )}
            </div>
            <div className="form-group col-lg-4 col-md-6">
              <label>Reenter A/c number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Reenter A/c number"
                name="reenterAccountNumber"
                value={reenterAccountNumber}
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={bankStatus !== "0" ? true : false}
              />
              {reenterAccountErr && (
                <div className="text-danger">{reenterAccountErr}</div>
              )}
            </div>
            <div className="form-group col-lg-4 col-md-6">
              <label>IFSC code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter IFSC Code"
                name="ifsc"
                value={ifsc}
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[a-zA-Z0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={bankStatus !== "0" ? true : false}
              />
              {ifscCodeError && (
                <div className="text-danger">{ifscCodeError}</div>
              )}
            </div>
            <div className="form-group col-lg-4 col-md-6">
              <label>Bank Branch</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Bank Branch"
                name="branchName"
                value={branchName}
                onChange={handleChange}
                disabled={bankStatus !== "0" ? true : false}
              />
              {branchNameErr && (
                <div className="text-danger">{branchNameErr}</div>
              )}
            </div>
          </div>
          {btnDisable || bankStatus == "1"
            ? ""
            : bankStatus !== "2" && (
                <button
                  className="btn btn_man w100px"
                  onClick={handleSubmit}
                  disabled={btnDisable || bankStatus == "1"}
                >
                  {btnDisable || bankStatus == "1" ? "Submited" : "Submit"}
                </button>
              )}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Bank;
