import React, { Component } from "react";
import Footer from "../Common/Footer";
import LoginHeader from "../Common/LoginHeader";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast, ToastContainer } from "react-toastify";
import { ProductName } from "../Common/ProductName";
import { formatDecimal } from "../../utils/decimalFormatter";
import moment from "moment";

export class Swap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ct: "",
      pt: "",
      ctChangeAmount: "1",
      convertTotal: "",
      selectedPair: "BTC",
      cryptoPair: "",
      coinList: [],
      selectedPrice: "",
      lastUpdated: moment().minute(),
      formattedTime: moment().format("lll"),
      segmentActive: "INR",
    };
  }

  componentDidMount() {
    this.getCryptoList();
    this.getCoinPriceByName(this.state.selectedPair);
  }

  getCryptoList = () => {
    axios.get(baseUrl + "/frontapi/cryptocoin_list").then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        const selectedCoin = resp.data.find(
          (item) => item.short_name === this.state.selectedPair
        );
        console.log("selectedCoin", selectedCoin);
        this.setState({ coinList: resp.data, cryptoPair: selectedCoin });
      }
    });
  };

  getCoinPriceByName = (value) => {
    let sendCoinData = {
      firstCoin: value,
      secondCoin: "USDT",
    };

    axios.post(baseUrl + "/frontapi/checkpair", sendCoinData).then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        this.setState({
          selectedPrice: resp.data.currentPairPrice,
          lastUpdatedMinute: moment().minute(),
          formattedTime: moment().format("lll"),
        });
        var convertTotal =
          parseFloat(this.state.ctChangeAmount) * resp.data.currentPairPrice;
        this.setState({
          convertValue: formatDecimal(convertTotal),
        });
      }
    });
  };

  changeCalculate = (e) => {
    const { name, value } = e.target;
    // Check if a minute has passed
    const currentMinute = moment().minute();
    if (currentMinute !== this.state.lastUpdatedMinute) {
      this.getCoinPriceByName(this.state.selectedPair);
    }
    if (name === "ctChangeAmount") {
      var convertTotal = parseFloat(value) * this.state.selectedPrice;
    }
    this.setState({ [name]: value, convertValue: formatDecimal(convertTotal) });
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "coin1") {
      this.setState({ selectedPair: value });
      this.getCoinPriceByName(value);
    }
  };

  handleClickDelete = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const totalValues = {
      coinId: this.state.ctId,
      coinAmount: this.state.ctChangeAmount,
      converTedValue: this.state.convertValue,
    };

    axios
      .post(baseUrl + "/frontapi/convertCoin", totalValues, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === false) {
          toast.error(resp.message);
        }
        if (resp.status === true) {
          toast.success("Amount Transferd Successfuly!");
          this.setState({ ctChangeAmount: "", convertValue: "" });
        }
      });
  };

  deleteRecordMgs = () => {
    if (!this.state.ctChangeAmount) {
      toast.error("Please enter Value First!");
      return;
    }
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to convert this token ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleClickDelete(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleChangeType = (type) => {
    this.setState({ segmentActive: type });
  };

  render() {
    const meta = {
      title: `Swap | ${ProductName}  Exchange`,
      description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    const selectedCoin = this.state.coinList.find(
      (item) => item.short_name === this.state.selectedPair
    );
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className=" dashbord_manbox ">
            <div className="container">
              <div className="walletClass"></div>
              <div className="banner_box">
                <div className="d-md-flex align-items-center text-center">
                  <h3 className="mr-auto mb-3 mb-md-0 text-md-left">
                    Easy Token Convertor
                  </h3>
                  <ul className="nav swap_tab">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#Staking_tab2"
                        onClick={() => this.handleChangeType("INR")}
                      >
                        Crypto To Fiat
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#menu1"
                        onClick={() => this.handleChangeType("CRYPTO")}
                      >
                        Crypto to Crypto
                      </a>
                    </li>
                  </ul>
                </div>

                <hr />

                <h4 className="tc mb-0">{this.state.selectedPair} to INR</h4>
                <p className="tc mt-1">
                  Convert Bitcoin ({this.state.selectedPair}) to Indian Rupee
                  (INR)
                </p>

                <div className="tab-content">
                  <div className="tab-pane active" id="Staking_tab2">
                    <div className="row">
                      <div className="form-group col-7 col-md-8">
                        <div className="input_box">
                          <small>Quantity</small>
                          <input
                            type="number"
                            id="busdAmt"
                            placeholder="1"
                            onkeyup="busdPrice()"
                            value={this.state.ctChangeAmount}
                            onChange={this.changeCalculate}
                            name="ctChangeAmount"
                            className="control_new"
                          />
                        </div>
                      </div>
                      <div className="form-group col-5 col-md-4 pl-0">
                        <div className="input_box d-flex align-items-center">
                          <img
                            src={
                              selectedCoin
                                ? `${baseUrl}/static/currencyImage/${selectedCoin.icon}`
                                : "assets/img/default.png"
                            } // Fallback image if logo not found
                            alt="token"
                            className="input_token"
                          />
                          <select
                            className="control_new"
                            value={this.state.selectedPair}
                            onChange={this.handleChange}
                            name="coin1"
                          >
                            {this.state.coinList.map((item) => {
                              return (
                                <option
                                  key={item.short_name}
                                  value={item.short_name}
                                >
                                  {item.short_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-7 col-md-8">
                        <div className="input_box">
                          <small>Price</small>
                          <input
                            type="number"
                            className="control_new"
                            onkeyup="reverceBusdPrice()"
                            id="tokenAmt2"
                            placeholder="Amount"
                            value={this.state.convertValue}
                            name="convertTotal"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group col-5 col-md-4 pl-0">
                        {this.state.segmentActive === "INR" ? (
                          <div className="input_box d-flex align-items-center">
                            <img
                              src="assets/img/inr.png"
                              alt="token"
                              className="input_token"
                            />
                            <select className="control_new" disabled>
                              <option>INR</option>
                            </select>
                          </div>
                        ) : (
                          <div className="input_box d-flex align-items-center">
                            <img
                              src={
                                this.state.cryptoPair
                                  ? `${baseUrl}/static/currencyImage/${this.state.cryptoPair.icon}`
                                  : "assets/img/default.png"
                              } // Fallback image if logo not found
                              alt="token"
                              className="input_token"
                            />
                            <select
                              className="control_new"
                              value={this.state.cryptoPair}
                              onChange={this.handleChange}
                              name="coin1"
                            >
                              {this.state.coinList.map((item) => {
                                return (
                                  <option
                                    key={item.short_name}
                                    value={item.short_name}
                                  >
                                    {item.short_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="tc mt-4 mb-0">As of {this.state.formattedTime}</p>
                <h5 className="tc mb-0">
                  1 {this.state.selectedPair} = {this.state.selectedPrice} INR
                </h5>
                <div className="  tc mt-4">
                  <button
                    className="btn btn_man pr-5 pl-5"
                    onClick={this.deleteRecordMgs}
                  >
                    Buy {this.state.selectedPair}
                  </button>
                </div>
              </div>
              <p className="tc mt-4">
                The current price of 1 {this.state.selectedPair} is ₹
                {this.state.selectedPrice} INR. Rubiex provides you the best{" "}
                {this.state.selectedPair}
                to INR conversion rates in real time.
              </p>
            </div>
          </div>
          <ToastContainer />
          <Footer />
        </div>
      </DocumentMeta>
    );
  }
}

export default Swap;
