import React from "react";
import RegisterForm from "./RegisterElement/RegisterForm";
import Footer from "../Common/Footer"
import {ToastContainer} from "react-toastify"
import MainHeader from "../Common/MainHeader"

class Register extends React.Component {
  constructor(props) {
    super(props);

    // You can initialize state or perform other setup here if needed.
  }
  render() {
    return (
      <div>
        <MainHeader />
        <RegisterForm referId={this.props.match.params.id}/>
        <ToastContainer limit={1} autoClose={5000} />
      </div>
    )
  }
}

export default Register;
