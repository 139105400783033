import React from "react";
import { Link } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
const baseUrl = myConstList.baseUrl;
class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      pair: "",
      email: "",
    };

    this.getData();
    this.getOpenPair();
  }

  componentWillMount() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }

    var theme = localStorage.getItem("theme");
    if (theme == null) {
      theme = false;
    }
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      this.handleChange(theme);
    }
  }
  getOpenPair = () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            pair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => {});
  };
  getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      const resp = res.data;

      this.setState({
        email: resp.data[0].email,
        mobile_no: resp.data[0].mobile_no,
      });
    });
  };
  showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null) {
      return (
        <ul className="">
          <li className="">
            <Link className="btn btn_man" to="/login-to-buy-sell-crypto">
              Log in
            </Link>
          </li>
          <li className="">
            <Link className="btn btn_man" to="/register-for-bitcoin-exchange">
              Sign up
            </Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="ment_right ml-auto ">
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
              </span>
              <div className="dropdown-menu2">
                
              {this.state.email ? this.state.email : this.state.mobile_no}
                <Link className="a_flex" to="/profile">
                  <i className="fa fa-user "></i> Profile
                </Link>
                <Link className="a_flex" to="/Kyc">
                  <i className="fa fa-id-card-o"></i> My KYC
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="fa fa-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={this.logout}>
                  <i className="fa fa-sign-out"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };
  logout = () => {
    var theme = localStorage.getItem("theme");
    localStorage.clear();
    localStorage.setItem("theme", theme);
    sessionStorage.clear();
  };
  handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");
    if (checked === true) {
      tags[0].classList.add("dark-theme");
      this.setState({ checked: false });
    } else {
      tags[0].classList.remove("dark-theme");
      this.setState({ checked: true });
    }
    localStorage.setItem("theme", checked);
  };
  render() {
    var token = localStorage.getItem("token");
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand navbar-brand2" to="/">
            <img
              src="/assets/img/logo.png"
              alt="header-Logo"
              className="logo logo1"
            />
            <img
              src="/assets/img/logo2.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </Link>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line align-items-center">
              <li className="nav-item">
                {!token ? (
                  <Link
                    className={
                      window.location.pathname === "/exchange"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={`/exchange/${this.state.pair}`}
                  >
                    Exchange
                  </Link>
                ) : (
                  <Link
                    className={
                      window.location.pathname === "/exchange"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={`/exchange/${this.state.pair}`}
                  >
                    Exchange
                  </Link>
                )}
              </li>
              <li className="nav-item">
                <Link
                  className={
                    window.location.pathname === "/stacking"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={`/stacking`}
                >
                  Stacking
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    window.location.pathname === "/launchpad"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={`/launchpad`}
                >
                  Launchpad
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    window.location.pathname === "/listing"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={`/listing`}
                >
                  Listing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    window.location.pathname === "/swap"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to={`/swap`}
                >
                  Swap
                </Link>
              </li>

              <li className="nav-item">
                {this.state.checked == false ? (
                  <img
                    src="/assets/img/sun.png"
                    alt=""
                    width="25px"
                    className="sun-img theme-img"
                    onClick={() => this.handleChange(this.state.checked)}
                    checked={this.state.checked}
                  />
                ) : (
                  <img
                    src="/assets/img/moon.png"
                    width="25px"
                    alt=""
                    className="moon-img theme-img"
                    onClick={() => this.handleChange(this.state.checked)}
                    checked={this.state.checked}
                  />
                )}
              </li>
            </ul>
            {/* <!--End of Main menu navigation list--> */}
          </div>
          <div className="dashbord_menu">{this.showHeaderPart()}</div>
        </div>
      </nav>
    );
  }
}
export default Nav;
