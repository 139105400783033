import React, { useEffect } from "react";

const ComingSoon = () => {
  useEffect(() => {
    let tags = document.getElementsByTagName("body");
    if (tags) {
      tags[0].classList.add("dark-theme");
    }

    return () => {};
  }, []);

  return (
    <div
      id="home"
      className="d-flex justify-content-center align-items-center home"
      style={{ height: "100vh" }}
    >
      <h1 className="text-center">Coming Soon...</h1>
    </div>
  );
};

export default ComingSoon;
