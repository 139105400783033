import axios from "axios";
import React from "react";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import { baseUrl } from "../../Common/BaseUrl";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePair: "/BTC/USDT",
    };
  }

  componentDidMount() {
    this.getOpenPair();
  }

  getOpenPair = () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            activePair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => {});
  };
  render() {
    const meta = {
      title: "Buy Bitcoin, Trading Platform | Rubiex  Exchange",
      description:
        "Rubiex  Exchange is Most Secure Crypto Exchange Platform. Buy And Sell Crypto With Multi Currency Crypto Exchange With Rubiex  Exchange's Official Website.",
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Rubiex  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <header className="home" id="home">
          <div className="container">
            <div className="home-outer position-relative">
              <div className="row align-items-center">
                <div className="col-md-5 col-12 order-md-2 m-auto p-md-0">
                  <div className="banner-right mt-3 mt-md-5 position-relative text-center">
                    {/* <img
                      src="assets/img/coin-banner-1.png"
                      alt=""
                      className="img-fluid rotateme logo1"
                    /> */}
                     {/* <img
                      src="assets/img/coin-banner-2.png"
                      alt=""
                      className="img-fluid rotateme logo2 bb_img"
                    /> */}
                    <img
                      src="assets/img/bb.png"
                      alt=""
                      className="img-fluid  "
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12  order-md-1">
                  <div className="banner-left">
                    <h1>
                    Buy, Sell, and Trade Cryptocurrencies on a secure, user-friendly, and lightning-fast platform.
                    </h1>
                    <p>
                    Trade Crypto with Confidence on Rubiex – The Next-Gen Exchange
                    </p>
                    <div className="banner-btn-outer">
                      <Link
                        to={`login-to-buy-sell-crypto`}
                        className="btn btn_man mr-3"
                      >
                       Get Started
                      </Link>
                      <Link
                        to={`register-for-bitcoin-exchange`}
                        className="btn btn_man"
                      >
                     Sign Up Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </DocumentMeta>
    );
  }
}

export default Header;
