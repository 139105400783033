import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as myConsList from "../../Common/BaseUrl";
import CryptoChart from "./CryptoChart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const baseUrl = myConsList.baseUrl;

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      cryptoData: [
        {
          name: "Bitcoin",
          price: "USD 53,260.20",
          percentage: "7.2%",
          unit: "BTC",
          icon: "assets/img/currency-1659701693850.png",
          chart: [50, 53, 52, 55, 53], // sample chart data
          change: "success", // success or critical
        },
        {
          name: "Ethereum",
          price: "USD 53,260.20",
          percentage: "1.8%",
          unit: "ETH",
          icon: "assets/img/currency-1659767985861.png",
          chart: [48, 49, 47, 50, 48],
          change: "critical",
        },
        {
          name: "Tether",
          price: "USD 53,260.20",
          percentage: "3.54%",
          unit: "USDT",
          icon: "assets/img/currency-1659701843012.png",
          chart: [49, 50, 49, 51, 50],
          change: "success",
        },
        {
          name: "Binance Coin",
          price: "USD 53,260.20",
          percentage: "3.24%",
          unit: "BNB",
          icon: "assets/img/currency-1659701648580.png",
          chart: [51, 53, 52, 54, 53],
          change: "success",
        },
      ],
    };

    this.GetCoinData();
  }

  GetCoinData = () => {
    axios.get(baseUrl + "/frontapi/Coin_Pair_Data", {}).then((resp) => {
      var resp = resp.data;

      this.setState({ CoinTableData: resp.data, coinTableStatus: resp.status });
    });
  };

  AddressListHtml = () => {
    if (this.state.coinTableStatus === true) {
      const html = [];

      this.state.CoinTableData.map(function (value, i) {
        if (value.coin_Second_name === "USDT") {
          html.push(
            <tr>
              <td>
                {
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coinIcon}
                  />
                }
              </td>
              <td>
                {value.coin_first_name} / {value.coin_Second_name}
              </td>

              <td>
                {parseFloat(
                  value.currentPrice ? value.currentPrice : 0
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  value.changePriceAmount ? value.changePriceAmount : 0
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  value.oneDayMaxPrice
                    ? value.oneDayMaxPrice
                    : parseFloat(
                        value.currentPrice ? value.currentPrice : 0
                      ).toFixed(2)
                )}
              </td>
              <td>
                {parseFloat(
                  value.oneDayMinPrice
                    ? value.oneDayMinPrice
                    : parseFloat(
                        value.currentPrice ? value.currentPrice : 0
                      ).toFixed(2)
                )}
              </td>
              <td>
                {parseFloat(
                  value.oneDayVolume ? value.oneDayVolume : 0
                ).toFixed(2)}
              </td>
            </tr>
          );
        }
      });

      return <tbody className="main">{html}</tbody>;
    }
  };
  changeClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  render() {
    // Slick slider settings
    const settings = {
      dots: true, // Show dots for navigation
      infinite: true, // Infinite scroll
      speed: 500, // Transition speed
      slidesToShow: 4, // Number of slides to show at once
      slidesToScroll: 1, // Number of slides to scroll at once
      responsive: [
        {
          breakpoint: 1024, // At 1024px screen width
          settings: {
            slidesToShow: 3, // Show 3 slides
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768, // At 768px screen width (tablets)
          settings: {
            slidesToShow: 2, // Show 2 slides
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // At 480px screen width (mobile)
          settings: {
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="p60 intro-main-outer" id="token" data-scroll-index="3">
          <div className="container">
            <div className="p60 pt-0 top_chart">
              <div className="crypto__main">
                <Slider {...settings}>
                  {this.state.cryptoData.map((crypto, index) => (
                    <div className="crypto-box" key={index}>
                      <div className="left">
                        <span className={`icon-${crypto.unit.toLowerCase()}`}>
                          <img
                            src={crypto.icon}
                            alt={crypto.name}
                            className="img-fluid"
                          />
                        </span>
                        <h6>{crypto.name}</h6>
                        <h6 className="price">{crypto.price}</h6>
                      </div>
                      <div className="right">
                        <CryptoChart data={crypto.chart} />
                        <p className={`sale ${crypto.change}`}>
                          {crypto.percentage}
                        </p>
                        <p className="unit">{crypto.unit}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
                {/* <div className="crypto-box">
                  <div className="left">
                    <span className="icon-btc">
                      <img
                        src="assets/img/currency-1659701693850.png"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    <h6>Bitcoin</h6>
                    <h6 className="price">USD 53,260.20</h6>
                  </div>
                  <div className="right">
                    <img src="assets/img/ch.png" className="img-fluid h26" />
                    <p className="sale success">7.2%</p>
                    <p className="unit">BTC</p>
                  </div>
                </div>
                <div className="crypto-box">
                  <div className="left">
                    <span className="icon-eth">
                      <img
                        src="assets/img/currency-1659767985861.png"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    <h6>Bitcoin</h6>
                    <h6 className="price">USD 53,260.20</h6>
                  </div>
                  <div className="right">
                    <img src="assets/img/ch.png" className="img-fluid h26" />
                    <p className="sale critical">1.8%</p>
                    <p className="unit">ETH</p>
                  </div>
                </div>
                <div className="crypto-box">
                  <div className="left">
                    <span className="icon-tether">
                      <img
                        src="assets/img/currency-1659701843012.png"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    <h6>Bitcoin</h6>
                    <h6 className="price">USD 53,260.20</h6>
                  </div>
                  <div className="right">
                    <img src="assets/img/ch.png" className="img-fluid h26" />
                    <p className="sale success">3.54%</p>
                    <p className="unit">USDT</p>
                  </div>
                </div>
                <div className="crypto-box">
                  <div className="left">
                    <span className="icon-bnb">
                      <img
                        src="assets/img/currency-1659701648580.png"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    <h6>Bitcoin</h6>
                    <h6 className="price">USD 53,260.20</h6>
                  </div>
                  <div className="right">
                    <img src="assets/img/ch.png" className="img-fluid h26" />
                    <p className="sale success">3.24%</p>
                    <p className="unit">BNB</p>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="intro-outer">
              <div className="row align-items-center">
                <div className="col-md-4 col-12">
                  <div className="intro-left">
                    <img
                      src="assets/img/ab-icon.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="intro-right">
                    <h2>Introducing Rubiex Exchange</h2>

                    <p className="mt-1">
                      At Rubiex, we believe in empowering people to take control
                      of their financial future through the world of
                      cryptocurrency. Founded with a vision to make digital
                      assets accessible to everyone, Rubiex is a next-generation
                      crypto exchange platform built on security, speed, and
                      user-centric design. Whether you're a seasoned trader or
                      just beginning your journey in crypto, our goal is to
                      provide a seamless and secure experience for all users.
                    </p>
                    <h5>Our Mission</h5>
                    <p className="mt-0">
                      {" "}
                      Our mission is simple: to revolutionize the way people
                      trade cryptocurrencies by offering an intuitive platform
                      that balances advanced features with ease of use. At
                      Rubiex, we strive to bridge the gap between the
                      traditional financial system and the innovative blockchain
                      economy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="white_box5">
              <h4 className=" ">Rubiex Market Price</h4>
              <div className="table-responsive">
                <div className="scrool_wallet_box">
                  <table className=" table table-striped mb-0 ">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Pair</th>
                        {/* <th>Coin</th> */}
                        <th>Last Price ($)</th>
                        <th>24h Change ($)</th>
                        <th>24h High ($)</th>
                        <th>24h Low ($)</th>
                        <th>24h Volume ($)</th>
                      </tr>
                    </thead>
                    {this.AddressListHtml()}
                  </table>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Table;
