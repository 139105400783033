import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
const baseUrl = myConstList.baseUrl;
const meta = {
  title: "Staking | rubiex  Exchange ",
  description:
    "rubiex  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://rubiexExchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Stacking = () => {
  const [coinTableStatus, setcoinTableStatus] = useState(false);
  const [coinTableData, setCoinTableData] = useState([]);
  const [stakeData, setStakeData] = useState([]);
  const [coinId, setCoinId] = useState("");
  const [coinName, setCoinName] = useState("");
  const [balance, setBalance] = useState(0);
  const [estimate, setEstimate] = useState("");
  const [stakeType, setStakeType] = useState("");
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [lockAmount, setLockAmount] = useState("");
  const [check, setCheck] = useState(false);
  const [image, setImage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // error
  const [lockError, setLockError] = useState("");
  const [checkError, setCheckError] = useState("");

  const stakeDate = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(baseUrl + "/frontapi/getAllStacking").then((res) => {
      var resp = res.data;
      setCoinTableData(resp.data);
      setcoinTableStatus(resp.status);
    });
  };

  const stakeNow = (value) => {
    setBalance(0);
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    const data = {
      id: value.currency,
      stakeId: value.id,
    };

    axios.post(baseUrl + "/frontapi/getCoinById", data, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        const newDate = new Date(new Date());
        newDate.setDate(newDate.getDate() + Number(value.duration));

        const formattedDate = newDate.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });

        if (resp.data.walletBalanceByCoinIdResult.length > 0) {
          setBalance(resp.data.walletBalanceByCoinIdResult[0].coin_amount);
        }

        setEndDate(formattedDate);
        setStakeType(value.type);
        setCoinName(value.short_name);
        setImage(value.icon);
        setEstimate(value.estimate);
        setMinAmount(value.lock_amount);
        setMaxAmount(value.max_lock_amount);
        setLockAmount(value.lock_amount);
        setStakeData(value);
        setCoinId(value.currency);
        setIsModalOpen(true);
        return false;
      }

      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
      }

      if (
        resp.message ==
        "Current stake is unavailable, Sorry for the inconvenience"
      ) {
        setTimeout(() => {
          getData();
        }, 2000);
      }

      if (resp.message == "Failed to authenticate token.") {
        toast.dismiss();
        toast.error("Login to stake crypto currency");

        setTimeout(() => {
          window.location.href = "/login-to-buy-sell-crypto";
        }, 2000);
      }
    });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const AddressListHtml = () => {
    const dataToRender = filteredData.length > 0 ? filteredData : coinTableData;

    if (coinTableStatus === true) {
      const html = [];

      dataToRender.forEach(function (value, i) {
        // Render table row for each filtered or original data item
        return html.push(
          <tr>
            {/* <td>{i + 1}</td> */}
            <td>
              {" "}
              {
                <img
                  src={baseUrl + "/static/currencyImage/" + value.icon}
                  alt=""
                />
              }
              {value.short_name}
            </td>

            <td>{value.estimate}%</td>
            <td>
              {value.duration + " Days"}{" "}
              {value.type === "Flexible" ? "(Flexible)" : ""}
              {/* {value.type === "Flexible"
                ? value.type
                : value.duration + " Days"} */}
            </td>
            <td>{value.lock_amount}</td>
            <td>
              <button className="btn btn_man" onClick={(e) => stakeNow(value)}>
                Stake Now
              </button>
            </td>
          </tr>
        );
      });

      return <tbody className="main">{html}</tbody>;
    }
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    const filteredData = coinTableData.filter((value) =>
      value.short_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchQuery(searchQuery);
    setFilteredData(filteredData);
  };

  const handleChange101 = (e) => {
    let { name, value, checked } = e.target;

    if (name === "lockAmount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");
      setLockAmount(newValue);

      if (!newValue || newValue == 0) {
        setLockError("Please enter lock amount");
        return false;
      }

      setLockAmount(newValue);
      setLockError("");
    }

    if (name === "check") {
      setCheck(checked);

      if (!checked) {
        setCheckError("Please select check box");
        return false;
      }
      setCheckError("");
    }
  };

  const submitStakes = (e) => {
    e.preventDefault();

    if (!lockAmount || lockAmount <= 0) {
      toast.dismiss();
      toast.error("Please enter lock amount");

      return false;
    }

    if (!check) {
      setCheckError("Please select check box");
      return false;
    }

    // min / max validation

    if (lockAmount < minAmount) {
      toast.dismiss();
      toast.error("Lock amount cannot be lower than min locked amount");
      return false;
    }

    if (lockAmount > maxAmount) {
      toast.dismiss();
      toast.error("Lock amount cannot be higher than max locked amount");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      locked_amount: lockAmount,
      coin_id: stakeData.currency,
      estimate: stakeData.estimate,
      stake_id: stakeData.id,
      type: stakeData.type,
      check: check,
    };
    axios
      .post(baseUrl + "/frontapi/addUserStakes", data, config)
      .then((res) => {
        var resp = res.data;

        if (resp.status === true) {
          toast.dismiss();
          toast.success(resp.message);
          closeModal();
          return false;
        }

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        }

        if (
          resp.message ==
          "Current stake is unavailable, Sorry for the inconvenience"
        ) {
          setTimeout(() => {
            getData();
            closeModal();
          }, 2000);
        }
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLockError("");
    setCheckError("");
    setCheck(false);
  };
  return (
    <div>
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        <div className="staking-banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 col-12">
                <div className="staking-left">
                  <h1>Staking</h1>
                  <h5 className="m-0">
                    Dedicated to increasing user staking income
                  </h5>
                </div>
              </div>

              {!localStorage.getItem("token") && (
                <div className="col-md-5 col-12">
                  <div className="staking-right">
                    <form>
                      <div className="view-detail text-center">
                        <p>
                          <b>Log in to to view details</b>
                        </p>
                        <a href="/login-to-buy-sell-crypto" className="btn btn_man">
                          Login
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="p60 intro-main-outer stake-table-outer"
          id=""
          data-scroll-index="3"
        >
          <div className="container">
            <div className="search-bar d-flex align-items-center">
              <form className="ml-auto order-md-2">
                <div className="form-group m-0 position-relative">
                  <input
                    type="text"
                    name=""
                    placeholder="Choose/Search Coin"
                    className="form-control pr-5"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <button
                    type="submit"
                    className="btn btn_man position-absolute"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </form>

              {localStorage.getItem("token") && (
                <div className="my-stack-btn pl-3 order-md-3">
                  <a href="/myStacking" className="btn btn_man">
                    My Stake
                  </a>
                </div>
              )}
              <div className="order-md-1 pr-md-3">
              Building a website is easy.<br/> Building a tool that people can explore
              and discover your product or service effectively, that is magical.
            </div>
            </div>
          
            <div className="white_box5">
              <h4 className="pt-3 pb-1">Staking</h4>
              <div className="table-responsive">
                <div className="scrool_wallet_box">
                  <table className=" table table-striped mb-0 ">
                    <thead>
                      <tr>
                        <th>Asset Name</th>
                        <th>Est. APR</th>
                        <th>Staking Duration</th>
                        <th>Min Locked Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {AddressListHtml()}
                  </table>
                </div>{" "}
              </div>
            </div>
          </div>
          <Modal
            className="modal-update stake-popup"
            show={isModalOpen}
            backdrop="static"
            onHide={closeModal}
          >
            <Modal.Header
              className="position-absolute border-0 p-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <div className="row  ">
                <div className="col-md-6 col-12  ">
                  <div className="stake-popup-left">
                    <div className="popup-heading">
                      <h4>Staking</h4>
                    </div>
                    <div className="notice-outer">
                      <div className="notice-inner d-flex ">
                        <div className="notice-left">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </div>
                        <div className="notice-right">
                          <p className="m-0 pl-2 op08">
                            Rubiex strives to offer its users only the best
                            Stacking projects. However, Rubiex only acts as a
                            platform to showcase projects and provide users with
                            related services, such as accessing funds on behalf
                            of the user and distributing earnings, etc.
                            Rubiex does not assume liability for any losses
                            incurred due to project on-chain contract security
                            issues.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="coin-outer">
                      <p>
                        <img
                          class="currency_icon"
                          src={baseUrl + "/static/currencyImage/" + image}
                          alt="" 
                        />
                        {coinName}
                      </p>
                    </div>
                    <div className="stake-form">
                      <form>
                        <div className="form-group">
                          <label>Type</label>
                          <input
                            type="text"
                            name=""
                            placeholder="Flexible"
                            value={stakeType}
                            disabled
                            className="form-control text-center"
                          />
                        </div>
                        <div className="form-group position-relative">
                          <label className="d-flex flex-wrap justify-content-between align-items-end">
                            Lock Amount
                            <small>
                              Available Amount {balance ? balance : 0}{" "}
                              {coinName}
                            </small>
                          </label>
                          <input
                            type="number"
                            name="lockAmount"
                            value={lockAmount}
                            onChange={handleChange101}
                            onKeyDown={handleValidation}
                            placeholder="Enter Amount"
                            className="form-control"
                          />
                        </div>
                        <p style={{ color: "red" }}>{lockError} </p>
                      </form>
                    </div>
                    <div className="stake-tips">
                      <ul>
                        <li>Locked Amount Limitation</li>
                        <li>
                          Minimum : {minAmount} {coinName}
                        </li>
                        <li>
                          Maximum : {maxAmount} {coinName}
                        </li>
                        {/* <li>Available Quota : 50 {coinName}</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 ">
                  <div className="stake-popup-right pl-4">
                    <div className="popup-heading">
                      <h4>
                        Summary{" "}
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </h4>
                    </div>
                    <div className="stake-summary">
                      <div className="stake-details">
                        <ul>
                          <li>
                            <p className="d-flex align-items-center justify-content-between">
                              <small>Stake Date</small> {stakeDate}
                            </p>
                          </li>

                          <li>
                            <p className="d-flex align-items-center justify-content-between">
                              <small>Stake End Date</small>{" "}
                              {/* {stakeType === "Flexible" ? "-" : endDate} */}
                              {endDate}
                            </p>
                          </li>
                        </ul>
                        <div className="est-apr d-flex flex-wrap align-items-center justify-content-between">
                          <p>Est. APR</p>
                          <p className="test-success">{estimate}%</p>
                        </div>
                      </div>
                      <div className="summary-detail">
                        <ul>
                          <li className="d-flex align-items-start">
                            <i class="fa fa-info-circle mt-1" aria-hidden="true"></i>
                            <p className="mt-0 pl-2 op08">
                              The APR is adjusted daily based on the on-chain
                              staking rewards, and the specific APR is subject
                              to the page display on the day.
                            </p>
                          </li>
                          <li className="d-flex align-items-start">
                            <i class="fa fa-info-circle mt-1" aria-hidden="true"></i>
                            <p className="mt-0 pl-2 op08">
                              APR does not mean the actual or predicted returns
                              in fiat currency.
                            </p>
                          </li>
                          <li className="d-flex align-items-start">
                            <input
                              type="checkbox"
                              placeholder=""
                              name="check"
                              checked={check}
                              onChange={handleChange101}
                              className="mt-1"
                            />
                            <p className="mt-0 pl-2 op08">
                              I have read and agreed to
                              <b>Rubiex Staking Service Agreement</b>
                            </p>
                          </li>
                          <p style={{ color: "red" }}>{checkError} </p>
                        </ul>
                        <div className="confirm-btn text-right">
                          <button
                            className="btn btn_man"
                            onClick={submitStakes}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>{" "}
        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default Stacking;
