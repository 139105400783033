import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../Common/BaseUrl";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import { EmailValidation } from "../../Validation/EmailValidation";
import { ProductName } from "../../Common/ProductName";
import DocumentMeta from "react-document-meta";

export const LoginForm = () => {
  const [type, setType] = useState("password");
  const [LoginStatus, setLoginStatus] = useState(false);
  const [loginVia, setLoginVia] = useState("Password");
  const [countryCode, setCountryCode] = useState("+91");
  const [timerText, setTimerText] = useState("Get Code");
  const [counter, setCounter] = useState(0);
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);
  const [disable, setDisable] = useState(false);
  const [pair, setPair] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [device, setDevice] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [vcode, setVCode] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [color, setcolor] = useState("red");
  const [textForCode, setTextForCode] = useState("");
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [automateCountryCode, setAutomateCountryCode] = useState("ae");

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "ae"
    );
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  const getOpenPair = async () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          setPair(
            `${res.data.data[0].first_name}/${res.data.data[0].second_name}`
          );
        }
      })
      .catch((err) => {});
  };
  const handleClick = () => {
    setType((prevType) => (prevType === "text" ? "password" : "text"));
  };

  const handleOnBlurPassword = (event) => {
    let passwordError = "";
    var Password = event.target.value;
    if (!Password) {
      passwordError = "This field is required";
    }
    setPassword(Password);
    setPasswordError(passwordError);
  };

  const setupGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setupDeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
    }
    if (isAndroid) {
      setDevice("Android");
    }
    if (isIOS) {
      setDevice("IOS");
    }
    if (isDesktop) {
      setDevice("Desktop");
    }
  };

  useEffect(() => {
    getData();
    getOpenPair();
    setupDeviceName();
    setupGeoInfo();
    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
  }, []);
  const handleChange1 = (event) => {
    let { name, value } = event.target;
    if (name === "password") {
      let error = "";
      if (!value) {
        error = "This field is required";
        setPasswordError(error);
      } else {
        setPasswordError(error);
      }
      setPassword(value);
    }
    if (name === "verification_code") {
      if (!value) {
        setVerificationCodeError("This field is required");
      } else {
        setVerificationCodeError("");
      }
      setVerificationCode(value);
    }
    // Handle other input fields similarly...
    if (name !== "password" && name !== "verification_code") {
      // Handle other fields...
      if (name === "email") {
        setEmail(value);
      }
    }
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    if (!vcode) {
      setMessage("This field is required");
      return;
    }
    let LoginData = {
      Password: password,
      code: vcode,
    };
    if (mobile) {
      LoginData.mobile = mobile;
    }
    if (email) {
      LoginData.email = email;
    }
    try {
      axios
        .post(baseUrl + "/frontapi/verifyLoginGoogleCode", LoginData)
        .then((resp) => {
          setMessage(resp.data.message);

          if (resp.data.status === true) {
            setcolor("green");
            localStorage.setItem("token", resp.data.token);
            window.location.href = "/exchange/BTC/USDT";
          } else {
            setcolor("red");
          }
        });
    } catch (error) {}
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setBtnDisable(true);
      setTimerText("Resend In");
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTimerText("");
        setTextForCode("Resend Code");
        setBtnDisable(false);
      }
    }, 1000);
  };

  const getCode = async () => {
    if (!email && !mobile) {
      setEmailError("This field is required");
      return false;
    }
    const registerData = {
      countryCode: countryCode,
      page: "login",
      selectedFiledType: selectedFiledType,
    };
    if (selectedFiledType === "email" && email) {
      registerData.email = email;
      if (
        !/^[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)
      ) {
        setEmailError("Please enter a valid email address");
        return false;
      }
    }
    if (selectedFiledType === "mobile" && mobile) {
      registerData.mobile = mobile;
      const isValidLength = mobile.length >= 5 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileError("Please enter a valid mobile number");
        return false;
      } else {
        setMobileError("");
      }
    }
    try {
      await axios
        .post(baseUrl + "/frontapi/getcode", registerData)
        .then((res) => {
          var resp = res.data;

          setVerificationCodeError("");

          if (resp.status === true) {
            toast.success(resp.message);
            startCountdown(30);
            setBtnDisable(false);
            setButtonDisableVerification(false);
            return false;
          }
          if (resp.status === false) {
            setBtnDisable(false);
            toast.error(resp.message);
            setButtonDisableVerification(true);
            return false;
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAuthChange = (e) => {
    setVCode(e.target.value);
    if (!e.target.value) {
      setMessage("This field is required");
    } else {
      setMessage("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    setTimeout(() => {
      setDisable(false);
    }, 4000);

    if (selectedFiledType === "email") {
      if (!email) {
        setEmailError("This field is required");
        return false;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError("Please enter a valid email address");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode && !email) {
        setVerificationCodeError("This field is required");
        setEmailError("This field is required");
        return false;
      }

      if (loginVia === "Password" && !email) {
        setEmailError("This field is required");
        return false;
      }
    } else {
      if (!countryCode || countryCode.length < 2) {
        setMobileError("Country code is required");
        return false;
      }

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(countryCode)) {
        setMobileError("Please enter a valid country code");
        return;
      }

      if (!mobile) {
        setMobileError("This field is required");
        return false;
      }

      if (mobile.length < 5 || mobile.length > 15) {
        setMobileError("Mobile number shoud be between 5 to 15 characters");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode && !mobile) {
        setVerificationCodeError("This field is required");
        setMobileError("This field is required");
        return false;
      }

      if (loginVia === "Password" && !mobile) {
        setMobileError("This field is required");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode) {
        setVerificationCodeError("This field is required");
        return false;
      }
    }

    if (loginVia === "Password" && !password) {
      setPasswordError("This field is required");
      return false;
    }

    if (mobileError || verificationCodeError || passwordError || emailError) {
      return false;
    }
    let values = {};

    if (loginVia === "Password") {
      values = {
        Password: password,
        selectedFiledType: selectedFiledType,
      };
    } else {
      values = {
        verification_code: verificationCode,
        selectedFiledType: selectedFiledType,
      };
    }
    if (email) {
      values.email = email;
    }
    if (mobile) {
      values.mobile = mobile;
      values.country_code = countryCode;
    }
    try {
      const resp = await axios.post(baseUrl + "/frontapi/login", values);
      const responseData = resp.data;
      setPasswordError(responseData.messagePass);
      setMessage(responseData.messagee);
      setMessage(responseData.message);

      if (responseData.status === false) {
        toast.dismiss();
        toast.error(responseData.message, { autoClose: 2000 });
        return;
      }
      if (responseData.status === true) {
        localStorage.setItem(
          "id",
          responseData.data[0] ? responseData.data[0].id : ""
        );
        localStorage.setItem(
          "mobile",
          responseData.data[0] ? responseData.data[0].mobile : ""
        );

        if (responseData.token !== undefined) {
          localStorage.setItem(
            "token",
            responseData.token ? responseData.token : ""
          );
          localStorage.setItem(
            "mobile",
            responseData.data[0] ? responseData.data[0].mobile : ""
          );
          localStorage.setItem(
            "id",
            responseData.data[0] ? responseData.data[0].id : ""
          );
          localStorage.setItem(
            "x-session-id",
            responseData.data[0] ? responseData.sessionId : ""
          );
          toast.dismiss();
          toast.success("login Successfully");
          const config = {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          };
          var userIdDataIp = {
            ip: localStorage.getItem("ip"),
            device: device,
            browser: browserName + " " + browserVersion,
            osName: osName + " " + osVersion,
            cityName: cityName,
            countryName: countryName,
          };

          async function getLoginInsert() {
            await axios.post(
              baseUrl + "/frontapi/login-insert",
              userIdDataIp,
              config
            );
          }
          getLoginInsert();
          setTimeout(() => {
            window.location.href = `/exchange/${pair}`;
          }, 1000);
        } else {
          setLoginStatus(true);
        }
      }
      return;
    } catch (error) {
      // Handle the error here
    }
  };

  const handleToggleClick = () => {
    if (loginVia === "Password") {
      setLoginVia("OTP");
      setVerificationCode("");
      // setMobileError("");
      // setEmailError("");
      setPasswordError("");
      setVerificationCodeError("");
    } else {
      setLoginVia("Password");
      // setMobileError("");
      setPassword("");
      setPasswordError("");
      setPassword("");
      setVerificationCodeError("");
    }
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     const data = {
  //       token: tokenResponse.code,
  //     };
  //     const userInfo = await axios.post(
  //       baseUrl + "/frontapi/google-login",
  //       data
  //     );
  //     if (userInfo.data) {
  //       localStorage.setItem(
  //         "id",
  //         userInfo.data.data[0] ? userInfo.data.data[0].id : ""
  //       );
  //       localStorage.setItem(
  //         "mobile",
  //         userInfo.data.data[0] ? userInfo.data.data[0].mobile : ""
  //       );
  //       localStorage.setItem(
  //         "token",
  //         userInfo.data.token ? userInfo.data.token : ""
  //       );
  //       toast.dismiss();
  //       toast.success("login Successfully");
  //       window.location.href = `/exchange/${pair}`;
  //     }
  //   },
  //   onError: (errorResponse) => console.log(errorResponse),
  //   flow: "auth-code",
  // });

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;

    setEmailError("");
    setEmail("");
    if (name === "phone") {
      setMobile(value);

      if (!countryCode) {
        setMobileError("Country code is required");
        return;
      }

      if (!value) {
        setMobileError("This field is required");
        return;
      }

      if (value.length < 5 || value.length > 15) {
        setMobileError("Mobile number shoud be between 5 to 15 characters");
        return;
      }

      setEmailError("");
      setMobileError("");

      if (textForCode === "Resend Code") {
        setTextForCode("Get Code");
      }
    }

    if (name === "countryCode") {
      if (!value || value.length < 2) {
        setCountryCode("+");
        setMobileError("Country code is required");
        return;
      }

      setCountryCode(value);

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(value)) {
        // setCountryCode("+");
        setMobileError("Please enter a valid country code");
        return;
      }

      setMobileError("");
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "Email") {
      if (textForCode === "Resend Code") {
        setTextForCode("Get Code");
      }
      // const isEmail = /\S+@\S+\.\S+/.test(value);
      // const isMobile = /^\d+$/.test(value);
      // if (isMobile) {
      //   setSelectedFiledType("mobile");

      //   setMobile(value);
      //   setEmailError("");
      //   return;
      // } else if (isEmail) {
      setSelectedFiledType("email");
      // }
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      setMobileError("");
    }
  };

  const switchButton = (value) => {
    setMobile("");
    setEmail("");
    setCountryCode("+91");
    setEmailError("");
    setMobileError("");
    if (value === "email") {
      setSelectedFiledType("email");
    }

    if (value === "mobile") {
      setSelectedFiledType("mobile");
    }
  };

  const meta = {
    title: "Low Cost Crypto Exchange App| Bitcoin Exchange",
    description: `From involving a large number of coins to executing voluminous orders, this platform takes care of everything. ${ProductName} Live Does The Buying And Selling Of Crypto Coin.`,
    canonical: `https://${ProductName}.com/login-to-buy-sell-crypto`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Exchange, Crypto Buying Selling, Buy Sell Bitcoin, BTC Exchange",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <div className="">
          <div className="register-main-outer position-relative  ">
            <span className="main-bg fadeInLeft animated"></span>
            <div className="register-inner">
              <div className="row m-0">
                <div className="col-md-5 col-12 m-auto">
                  <div className="register-right fadeInRight animated ">
                    <div className="heading-link-outer">
                      <div className="heading d-flex mb-2 align-items-center">
                        <h3 className=" ">Login</h3>
                        <div className="login_tab d-inline-flex ml-auto">
                          <a
                            className={
                              selectedFiledType === "email" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              switchButton("email");
                            }}
                            href="#!"
                          >
                            {" "}
                            Email
                          </a>
                          <a
                            className={
                              selectedFiledType === "mobile" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              switchButton("mobile");
                            }}
                            href="#!"
                          >
                            {" "}
                            Mobile
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="register-form">
                      {!LoginStatus ? (
                        <form>
                          <div className="form-group position-relative">
                            {selectedFiledType === "mobile" ? (
                              <>
                                <label className="form-label">
                                  Mobile Number
                                </label>

                                <div className="row">
                                  <div className="col-3">
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      placeholder="Country Code"
                                      name="countryCode"
                                      autoFocus
                                      value={countryCode}
                                      onChange={handleChangeMobile}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-9 pl-0">
                                    <input
                                      className="form-control"
                                      type="number"
                                      autoComplete="off"
                                      placeholder="Enter Mobile Number"
                                      name="phone"
                                      autoFocus
                                      value={mobile}
                                      onChange={handleChangeMobile}
                                      disabled={counter == 0 ? false : true}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <label className="form-label">Email</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Enter Email Address"
                                  name="Email"
                                  autoFocus
                                  value={email}
                                  maxLength="64"
                                  onChange={handleChangeEmail}
                                  // onPaste={(e) => e.preventDefault()}
                                  disabled={counter == 0 ? false : true}
                                />
                              </>
                            )}
                            {mobileError && (
                              <div style={{ color: "red" }} name="mobile">
                                {mobileError}
                              </div>
                            )}
                            {emailError && (
                              <div style={{ color: "red" }} name="email">
                                {emailError}
                              </div>
                            )}
                          </div>

                          {loginVia == "Password" ? (
                            <div className="form-group">
                              <label className="form-label">Password</label>
                              <div className="eye_pass">
                                <input
                                  className="form-control"
                                  autoComplete="off"
                                  type={type}
                                  name="password"
                                  id="password"
                                  maxLength="20"
                                  placeholder="Enter Password"
                                  value={password}
                                  onChange={handleChange1}
                                  onBlur={handleOnBlurPassword}
                                />
                                <span style={{ color: "red" }}>
                                  {passwordError}
                                </span>

                                <span
                                  className="password__show eye1"
                                  onClick={handleClick}
                                >
                                  {type === "text" ? (
                                    <i className="fa fa-eye"></i>
                                  ) : (
                                    <i className="fa fa-low-vision"></i>
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Verification Code"
                                  name="verification_code"
                                  id="verification_code"
                                  value={verificationCode}
                                  onChange={handleChange1}
                                  disabled={buttonDisableVerification}
                                  onBlur={handleOnBlurPassword}
                                  aria-label="Input group example"
                                  aria-describedby="btnGroupAddon"
                                />
                                <div className="input-group-prepend">
                                  <button
                                    className="btn btn_man"
                                    id="btnGroupAddon"
                                    type="button"
                                    onClick={getCode}
                                    disabled={btnDisable}
                                  >
                                    {timerText}
                                    {"  "}
                                    {counter == 0 ? textForCode : counter}
                                  </button>
                                </div>
                              </div>
                              <div style={{ color: "red" }}>
                                {verificationCodeError}
                              </div>
                            </div>
                          )}
                          <div className="d-flex mb-3">
                            <div className="via-otp">
                              <Link onClick={handleToggleClick}>
                                Via{" "}
                                {loginVia === "Password" ? "OTP" : "Password"}
                              </Link>
                            </div>

                            <div className="ml-auto">
                              <div className="forgot-btn text-right">
                                <Link to="/forgot" className="a_color">
                                  Forgot password ?
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="form-group log-btn mx-auto">
                            <button
                              className="btn w100 btn_man"
                              type="submit"
                              disabled={disable}
                              onClick={handleSubmit}
                            >
                              Log In
                            </button>
                          </div>
                          {/* <div className="form-group text-center">OR</div>
                          <div className="form-group log-btn mx-auto">
                            <button
                              className="btn w100 btn_border"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                googleLogin();
                              }}
                            >
                              <img
                                src={"/assets/img/google.svg"}
                                alt=""
                                className="google_icon mr-2"
                              />{" "}
                              Continue with Google{" "}
                            </button>
                          </div> */}

                          <div className="form-group text-center">
                            <span>Create a new account </span>
                            <Link
                              to="/register-for-bitcoin-exchange"
                              className=""
                            >
                              Signup
                            </Link>
                          </div>
                        </form>
                      ) : (
                        <div className="">
                          <h5>Two Factor Authentication</h5>
                          <form>
                            <div className="form-group">
                              <label className="fw-500">Enter Auth Code</label>
                              <input
                                type="text"
                                className="form-control"
                                name="vcode"
                                placeholder="Enter Code"
                                value={vcode}
                                onChange={handleAuthChange}
                              />
                            </div>
                            <div className="form-group">
                              <button
                                onClick={verifyCode}
                                className="btn btn_man w100px"
                              >
                                Verify
                              </button>
                              <p style={{ color }}>{message} </p>
                            </div>
                          </form>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer autoClose={3000} />
          </div>
        </div>
      </DocumentMeta>
    </>
  );
};
