import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Nav from "../Common/Nav";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DocumentMeta from "react-document-meta";
import moment from "moment";
const baseUrl = myConstList.baseUrl;
const meta = {
  title: "My Staking | rubiex  Exchange ",
  description:
    "rubiex  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://rubiex  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const MyStacking = () => {
  const [coinTableStatus, setcoinTableStatus] = useState(false);
  const [coinTableData, setCoinTableData] = useState([]);
  const [stakeData, setStakeData] = useState([]);
  const [stakeStatus, setStakeStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyStakeData, setHistoryStakeData] = useState([]);
  const [historyStakeStatus, setHistoryStakeStatus] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyCoin, setHistoryCoin] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/getAllUserStake", {}, config)
      .then((res) => {
        var resp = res.data;
        setCoinTableData(resp.data);
        setcoinTableStatus(resp.status);
      });
  };

  const stakeInfoApi = (value) => {
    const data = {
      stake_id: value.stake_id,
    };

    axios.post(baseUrl + "/api/getUserStakeById", data).then((res) => {
      setIsModalOpen(true);
      setStakeData(res.data);
      setStakeStatus(res.status);
    });
  };

  const stakeHistoryData = (value) => {
    setHistoryCoin(value.short_name);
    const data = {
      id: value.id,
    };

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    axios
      .post(baseUrl + "/frontapi/getUserStakingHistory", data, config)
      .then((res) => {
        setIsHistoryModalOpen(true);
        setHistoryStakeData(res.data.data);
        setHistoryStakeStatus(res.data.status);
      });
  };

  const covertDateFormat = (date) => {
    // Parse the input date
    const parsedDate = moment(date, "YYYY/MM/DD");

    // Format the date in the desired format
    const formattedDate = parsedDate.format("DD/MM/YYYY");

    return formattedDate;
  };

  const AddressListHtml = () => {
    if (coinTableStatus === true) {
      const html = [];

      coinTableData.map(function (value, i) {
        return html.push(
          <tr>
            <td>
              <>
                <img
                  src={baseUrl + "/static/currencyImage/" + value.icon}
                  alt="currency_image"
                />
                {value.short_name}
              </>
            </td>
            <td>
              {value.stake_id}{" "}
              {/* <button
                type="button"
                class="btn btn_man"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => {
                  stakeInfoApi(value);
                }}
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </button> */}
            </td>

            <td>{value.type}</td>

            <td>{value.locked_amount}</td>

            <td>{value.estimate} %</td>

            <td>
              {value.current_amount}
              {"   "}
              {value.type === "Days" && (
                <a className="cursor-pointer"
                  type="button"
                  // class="btn btn_man"
                  data-toggle="modalHistory"
                  data-target="#exampleHistoryModalCenter"
                  onClick={() => {
                    stakeHistoryData(value);
                  }}
                >
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </a>
              )}
            </td>

            <td>{covertDateFormat(value.start_date)}</td>

            <td>{value.end_date ? covertDateFormat(value.end_date) : "-"}</td>

            <td>
              {value.remark == "blocked_by_admin"
                ? "Blocked By Admin"
                : value.remark == "stopped_by_admin"
                ? "Stopped By Admin"
                : value.remark == "un_stake_amount"
                ? "UnStaked By User"
                : value.remark == "staking_closed_amount"
                ? "Staking Closed"
                : "-"}
            </td>

            <td>{value.status === "0" ? "Running" : "Closed"}</td>

            <td>
              {value.status === "0" && value.type == "Flexible" ? (
                <button
                  className="btn btn_man"
                  onClick={(e) => deleteRecordMgs(value)}
                >
                  UnStake
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      });

      return <tbody className="main">{html}</tbody>;
    }
  };

  const tableDataHtml = () => {
    if (stakeStatus == true) {
      const html = [];
      stakeData &&
        stakeData.map((value, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>
                <>
                  {" "}
                  {
                    <img
                      style={{ maxWidth: "40px" }}
                      src={baseUrl + "/static/currencyImage/" + value.icon}
                      alt="currency_image"
                    />
                  }{" "}
                  {value.short_name}
                </>
              </td>
              <td>
                {value.type === "Flexible"
                  ? value.type
                  : value.duration + " " + value.type}
              </td>
              <td>{value.estimate}%</td>
              <td>
                {value.lock_amount} {value.short_name}
              </td>
            </tr>
          );
        });
      return html;
    }
  };

  const historyTableData = () => {
    const html = [];
    if (historyStakeStatus == true) {
      historyStakeData.map((value, i) => {
        // Parse the date using moment.js
        const date = moment(value.created);

        // Format the date as "dd-MM-YY"
        const formattedDate = date.format("DD/MM/YYYY");

        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>
              {Math.abs(value.coin_amount)} {historyCoin}
            </td>
            <td>{formattedDate}</td>
          </tr>
        );
      });
      return html;
    } else {
      html.push(
        <tr>
          <td></td>

          <td>
            {" "}
            <h4 style={{ alignItems: "center" }}>No data</h4>
          </td>

          <td></td>
        </tr>
      );

      return html;
    }
  };

  const deleteRecordMgs = (record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to un stake this stake?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteRecord = (record) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    const data = {
      id: record.id,
      coin_id: record.coin_id,
      current_amount: record.current_amount,
      stake_id: record.stake_id,
    };
    axios
      .post(baseUrl + "/frontapi/unStake", data, config)
      .then((res) => {
        var resp = res.data;
        toast.dismiss();
        if (resp.status === true) {
          toast.success(resp.message);
          getData();
        } else {
          toast.error(resp.message);
        }
      })
      .catch();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        <div
          className="intro-main-outer stake-table-outer my-stack-outer p60 pt-5 mt-3 mt-md-5"
          id=" "
          data-scroll-index="3"
        >
          <div className="container">
            <div className="white_box5">
              <h4 className="pb-2 mb-0"> My Stakes</h4>
              <div className="table-responsive">
                <div className="scrool_wallet_box ">
                  <table className=" table table-striped mb-0 ">
                    <thead>
                      <tr>
                        <th>Asset Name</th>
                        <th>Staking ID </th>
                        <th>Type </th>
                        <th>Amount Staked</th>
                        <th>EST.APR</th>
                        <th>Maturity Amount</th>
                        <th>Staking Start Date</th>
                        <th>Staking End Date</th>
                        <th>Remark</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {AddressListHtml()}
                  </table>
                </div>{" "}
              </div>
            </div>
          </div>

          <Modal
            className="modal-update stake-popup"
            show={isModalOpen}
            onHide={closeModal}
          >
            <Modal.Header
              className="position-absolute border-0 p-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <div className="row m-0">
                <div className="col-md-6 col-12 p-0">
                  <div className="stake-popup-left">
                    <div className="popup-heading">
                      <h4>Staking Info</h4>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="scrool_wallet_box">
                    <table className="table w-100">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Currency Name</th>
                          <th>Stake Type</th>
                          <th>EST. APR</th>
                          <th>Min. Lock</th>
                        </tr>
                      </thead>
                      <tbody>{tableDataHtml()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="modal-update stake-popup"
            show={isHistoryModalOpen}
            onHide={closeHistoryModal}
          >
            <Modal.Header
              className="position-absolute border-0 p-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <div className="row m-0">
                <div className="col-md-6 col-12 p-0">
                  <div className="stake-popup-left">
                    <div className="popup-heading">
                      <h4>Staking History Info</h4>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="scrool_wallet_box">
                    <table className="table w-100">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Payout</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>{historyTableData()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <ToastContainer />
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default MyStacking;
