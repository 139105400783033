import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { ImageValidation } from "../Validation/ImageValidation";
import { passportApi, passportNumberApi, checkKycStatus } from "./Apis";
import * as myConstList from "../Common/BaseUrl";
import Select from "react-select";
import countryList from "react-select-country-list";
import Rejectreason from "./Rejectreason";
const baseUrl = myConstList.baseUrl;

function Passport(props) {
  const [image, setImage] = useState("/assets/img/icon2.png");
  const [bimage, setBImage] = useState("/assets/img/icon2.png");
  const [passportStatus, setPassportStatus] = useState("0");
  const [passportNumberStatus, setPassportNumberStatus] = useState("0");
  const [passportImage, setPassportImage] = useState("");
  const [passportImageError, setPassportImageError] = useState("");
  const [useruploadPassportImage, setUseruploadPassportImage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportNumberError, setPassportNumberError] = useState("");
  const [docType, setDocType] = useState("Passport");
  const [passportImageBack, setPassportImageBack] = useState("");
  const [passportImageBackError, setPassportImageBackError] = useState("");
  const [countryIsoError, setCountryIsoError] = useState("");
  const [useruploadPassportBackImage, setUseruploadPassportBackImage] =
    useState("");
  const [openBackImg, setopenBackImg] = useState(false);

  const [value, setValue] = useState("");
  const [defaultValues, setDefaultValues] = useState(null);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
    if (!value) {
      setCountryIsoError("This field is required");
      return;
    }
    setCountryIsoError("");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent", // background color of the input field
      borderColor: state.isFocused ? "grey" : "grey", // border color of the input field
      boxShadow: state.isFocused ? "0 0 0 1px grey" : "none", // box shadow when focused
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "grey"
        : state.isFocused
        ? "#fff"
        : "white", // background color of options
      color: state.isSelected ? "white" : "black", // text color of options
      padding: 10, // padding of options
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // text color of the input when searching
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // placeholder color if needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // color of the selected value in the input field
    }),
  };

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setPassportNumberStatus(
        props.userinfo[0].document_number_status &&
          props.userinfo[0].document_number_status
      );
      setDocType(
        props.userinfo[0].doc_type ? props.userinfo[0].doc_type : "Passport"
      );
      setPassportStatus(props.userinfo[0].passport_status);
      props.userinfo[0].passport_image &&
        setImage(
          baseUrl + "/static/upload/" + props.userinfo[0].passport_image
        );
      if (props.userinfo[0].passport_image_back) {
        setBImage(
          baseUrl + "/static/upload/" + props.userinfo[0].passport_image_back
        );
      } else {
        setBImage("/assets/img/icon2.png");
      }
      setRejectionReason(props.userinfo[0].d5_rejectResion);
      setPassportNumber(props.userinfo[0].passport_number);
      if (props.userinfo[0].country_code_iso) {
        setValue({
          value: props.userinfo[0].country_code_iso,
          label: props.userinfo[0].country,
        });
      }

      setDefaultValues({
        value: props.userinfo[0].country_code_iso,
        label: props.userinfo[0].country,
      });
      setopenBackImg(true);
    }
  }, [props]);

  const handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "docType") {
      setUseruploadPassportImage("");
      setUseruploadPassportBackImage("");
      setDocType(e.target.value);
      setPassportNumber("");
      setPassportNumberError("");
      if (value == "Driving Licence") {
        setopenBackImg(true);
      }
      if (value == "Passport") {
        setopenBackImg(true);
      }
      if (value == "National Identity Card") {
        setopenBackImg(true);
      }
      if (value == "Aadhar") {
        setopenBackImg(true);
      }
    }
    if (name == "passportNumber") {
      setPassportNumber(e.target.value.toUpperCase());
      if (e.target.value === "") {
        setPassportNumberError("This field is required");
        return false;
      }
      if (docType == "Passport") {
        setopenBackImg(true);
        if (!(e.target.value.length >= 7 && e.target.value.length <= 12)) {
          setPassportNumberError(
            "Please enter a valid passport number without any space"
          );
          return false;
        }
      }
      if (docType == "Driving Licence") {
        setopenBackImg(true);
        if (!(e.target.value.length >= 9 && e.target.value.length <= 16)) {
          setPassportNumberError(
            "Please enter a valid driving licence number without any space"
          );
          return false;
        }
      }
      if (docType == "National Identity Card") {
        setopenBackImg(true);
        if (!(e.target.value.length >= 9 && e.target.value.length <= 16)) {
          setPassportNumberError(
            "Please enter a valid national identity number without any space"
          );
          return false;
        }
      }
      if (docType === "Aadhar") {
        setopenBackImg(true);
        if (!(value.length == 12)) {
          setPassportNumberError(
            "Please enter a valid aadhaar number without any space"
          );
          return false;
        }
        if (value[0] == 0) {
          setPassportNumberError(
            "Please enter a valid aadhaar number without any space"
          );
          return false;
        }
      }
      setPassportNumberError("");
    }

    if (name == "passportImage") {
      setPassportImage(e.target.files[0]);
      let error = ImageValidation("Passport", e.target.files[0]);
      setPassportImageError(error);
      e.target.files[0]
        ? setUseruploadPassportImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadPassportImage("");
    }
    if (name == "passportImageBack") {
      setPassportImageBack(e.target.files[0]);
      let error = ImageValidation("Passport", e.target.files[0]);
      setPassportImageBackError(error);
      e.target.files[0]
        ? setUseruploadPassportBackImage(URL.createObjectURL(e.target.files[0]))
        : setUseruploadPassportBackImage("");
    }
  };

  const handleSubmit = async () => {
    if (!passportNumber) {
      setPassportNumberError("This field is required");
      return false;
    }
    if (passportNumberStatus === "0") {
      setPassportNumberError(`Please submit ${docType} number.`);
      return false;
    }
    if (docType === "Passport") {
      if (!(passportNumber.length >= 7 && passportNumber.length <= 12)) {
        setPassportNumberError(
          "Please enter a valid passport number without any space"
        );
        return false;
      }
    }
    if (docType === "Driving Licence") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid driving licence number without any space"
        );
        return false;
      }
    }
    if (docType === "National Identity Card") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid national identity number without any space"
        );
        return false;
      }
    }
    if (docType === "Aadhar") {
      setopenBackImg(true);
      if (!(passportNumber.length == 12)) {
        setPassportNumberError(
          "Please enter a valid aadhaar number without any space"
        );
        return false;
      }
      if (passportNumber[0] == 0) {
        setPassportNumberError(
          "Please enter a valid aadhaar number without any space"
        );
        return false;
      }
    }
    if (!passportImage) {
      setPassportImageError("This field is required");
      return false;
    }
    if (passportImageError) {
      return false;
    }
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var data = new FormData();
    data.append("docType", docType);
    data.append("passportNumber", passportNumber);
    data.append("passportImage", passportImage);
    data.append("passportImageBack", passportImageBack);

    let result = await passportApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      toast.success(`${docType} uploaded successfully`);
      props.resendResult(true);
      setPassportNumberError("");
    } else {
      setPassportNumber("");
      toast.error(result.message);
      props.resendResult(true);
    }
  };

  const submitPassportNumber = async (e) => {
    e.preventDefault();

    if (!value) {
      setCountryIsoError("This field is required");
      return;
    }

    if (!passportNumber) {
      setPassportNumberError("This field is required");
      return false;
    }
    if (docType === "Passport") {
      if (!(passportNumber.length >= 7 && passportNumber.length <= 12)) {
        setPassportNumberError(
          "Please enter a valid passport number without any space"
        );
        return false;
      }
    }
    if (docType === "Driving Licence") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid driving licence number without any space"
        );
        return false;
      }
    }
    if (docType === "National Identity Card") {
      if (!(passportNumber.length >= 9 && passportNumber.length <= 16)) {
        setPassportNumberError(
          "Please enter a valid national identity number without any space"
        );
        return false;
      }
    }

    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      passportNumber: passportNumber,
      docType: docType,
      countryIso: value.value,
      country: value.label,
    };

    let result = await passportNumberApi(data, config);
    setBtnDisable(false);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
      setPassportNumberError("");
    } else {
      setPassportNumber("");
      toast.error(result.message);
      props.resendResult(true);
    }
  };

  return (
    <>
      <div className="passport-outer">
        <div className="row">
          <div className="col-md-3 col-12"></div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">Identity Proof</h6>
              <b className="mt-0">Requirements</b>
              <ul className="dit_ul mb-4">
                <li>
                  The photo/scan must be clear, in color and uncompressed.
                </li>
                <li>The photo/scan must be not edited and not cropped.</li>
                <li>
                  The document must be valid at least 3 months after the
                  verification submission date.
                </li>
                <li>should exactly match the ones in your document.</li>
                <li>Min size 10 Kb/ Max size 5 Mb.</li>
              </ul>
              <div className="row">
                <div className="form-group col-md-6 col-12 ">
                  <div className="mb-3">
                    <label className="form-label">Country</label>
                    {props.userinfo &&
                      defaultValues &&
                      defaultValues.value &&
                      options && (
                        <Select
                          options={options}
                          styles={customStyles}
                          defaultValue={defaultValues}
                          isDisabled={true}
                        />
                      )}
                    {props.userinfo &&
                      defaultValues &&
                      !defaultValues.value &&
                      options && (
                        <Select
                          options={options}
                          value={value}
                          onChange={changeHandler}
                          styles={customStyles}
                          isClearable={true}
                          isSearchable={true}
                        />
                      )}
                    {countryIsoError && (
                      <div className="text-danger">{countryIsoError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Document</label>
                    <select
                      value={docType}
                      className="form-control"
                      onChange={handleChange}
                      name="docType"
                      disabled={
                        passportNumberStatus == "0" ||
                        passportNumberStatus == "3"
                          ? false
                          : true
                      }
                    >
                      <option value="Passport">Passport</option>

                      <option value="Driving Licence">Driving Licence</option>
                      <option value="Aadhar">Aadhar Card</option>
                    </select>
                  </div>
                  <label>{docType} Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Enter ${docType} Number`}
                    name="passportNumber"
                    value={passportNumber}
                    onChange={handleChange}
                    disabled={
                      passportNumberStatus === "0" ||
                      passportNumberStatus === "3"
                        ? false
                        : true
                    }
                    onKeyPress={(event) => {
                      if (!/[A-Za-z0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {passportNumberError && (
                    <div className="text-danger">{passportNumberError}</div>
                  )}

                  <div>
                    {passportNumberStatus === "0" ||
                    passportNumberStatus === "3" ? (
                      <button
                        className="btn btn_man w100px mt-2"
                        onClick={submitPassportNumber}
                      >
                        Save
                      </button>
                    ) : (
                      <button className="btn btn_man w100px mt-2" disabled>
                        Submited
                      </button>
                    )}
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-6"></div>
                <div className="form-group col-lg-4 col-md-6">
                  <div id="file-upload-form" className="uploader mt-3">
                    <input
                      type="file"
                      id="passportImage"
                      name="passportImage"
                      onChange={handleChange}
                      accept="image/*"
                      capture
                      disabled={
                        passportStatus == "0" || passportStatus == "3"
                          ? false
                          : true
                      }
                    />
                    <label for="passportImage" id="file-drag">
                      {passportStatus === 3 ? (
                        <img
                          src={image}
                          className="img-fluid22"
                          alt="Identity"
                        />
                      ) : (
                        <img
                          src={
                            useruploadPassportImage !== ""
                              ? useruploadPassportImage
                              : image
                          }
                          className="img-fluid22"
                          alt="Identity"
                        />
                      )}
                      <div id="start">
                        <span className="fasi"></span>
                        {docType === "National Identity Card" ? (
                          <div>Upload {docType} Front Image</div>
                        ) : (
                          <div>Upload {docType} Image</div>
                        )}
                        <div id="notimage" className="hidden">
                          Please select an image
                        </div>
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages"></div>
                        <progress
                          className="progress"
                          id="file-progress"
                          value="0"
                        >
                          <span>0</span>%
                        </progress>
                      </div>
                    </label>
                  </div>
                  <p className="mt-0">*only jpeg, jpg & png files supported</p>
                  {passportImageError && (
                    <div className="text-danger">{passportImageError}</div>
                  )}
                  <Rejectreason
                    reason={rejectionReason}
                    status={passportStatus}
                  />
                </div>
                {openBackImg ? (
                  <div className="form-group col-lg-4 col-md-6">
                    <div id="file-upload-form" className="uploader mt-3">
                      <input
                        type="file"
                        id="passportImageBack"
                        name="passportImageBack"
                        onChange={handleChange}
                        disabled={
                          passportStatus == "0" || passportStatus == "3"
                            ? false
                            : true
                        }
                      />
                      <label for="passportImageBack" id="file-drag">
                        {passportStatus === 3 ? (
                          <img
                            src={bimage}
                            className="img-fluid22"
                            alt="Identity"
                          />
                        ) : (
                          <img
                            src={
                              useruploadPassportBackImage !== ""
                                ? useruploadPassportBackImage
                                : bimage
                            }
                            className="img-fluid22"
                            alt="Identity"
                          />
                        )}
                        <div id="start">
                          <span className="fasi"></span>
                          {docType === "National Identity Card" ? (
                            <div>Upload {docType} Back Image</div>
                          ) : (
                            <div>Upload {docType} Image</div>
                          )}
                          <div id="notimage" className="hidden">
                            Please select an image
                          </div>
                        </div>
                        <div id="response" className="hidden">
                          <div id="messages"></div>
                          <progress
                            className="progress"
                            id="file-progress"
                            value="0"
                          >
                            <span>0</span>%
                          </progress>
                        </div>
                      </label>
                    </div>
                    <p className="mt-0">
                      *only jpeg, jpg & png files supported
                    </p>
                    {passportImageBackError && (
                      <div className="text-danger">
                        {passportImageBackError}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {passportStatus !== "2" && (
                <button
                  className="btn btn_man w100px"
                  onClick={handleSubmit}
                  disabled={btnDisable || passportStatus == "1"}
                >
                  {btnDisable || passportStatus == "1" ? "Submited" : "Submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Passport;
