import React,{useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { baseUrl } from '../Common/BaseUrl'
import { ToastContainer, toast } from "react-toastify";
import Nav from '../Common/Nav';
import Footer from '../Common/Footer';
function RejectVerification() {
    const {id}=useParams()
   
    useEffect(() => {
        rejectAmount()
    }, [id])
    const rejectAmount=()=>{
        const data={
            id:id
        }
        axios.post(baseUrl+"/api/coinRefundOnRejectByUser",data).then((res)=>{
             if(res.data.success){
                toast.success(res.data.message)
             }
             else{
                toast.error(res.data.message)
             }
        }).catch()
    }
    const changeTheme=()=>{
      
    }
  return (
    <div className="">
     <Nav/>
	  <div className="new_inpage ">
      <div className="container">
		<div className="vr_img">
			<img src="assets/img/wrong.gif" alt="wrong" className="m-auto" />
		</div>
   </div>
    </div>

    <ToastContainer/>
    <Footer/>
  </div>
    // <div>
    //   <p>Rejected</p>
    //   <ToastContainer/>
    // </div>
  )
}

export default RejectVerification
