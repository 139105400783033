import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import "react-confirm-alert/src/react-confirm-alert.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentMeta from "react-document-meta";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "LaunchPad | rubiex  Exchange ",
  description:
    "rubiex  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://rubiex  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const LaunchPad = () => {
  const jwt = localStorage.getItem("token");
  const [coinTableStatus, setcoinTableStatus] = useState(false);
  const [coinTableData, setCoinTableData] = useState([]);
  const [launchapadRejectedData, setLaunchapadRejectedData] = useState([]);
  const [urlLink, setUrlLink] = useState("");
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0");
  let day = String(currentDate.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    getData();
    getUrl();
    getRejectedByUser();
  }, []);

  const getData = () => {
    // only those data which are accepted and unblocked
    axios.get(baseUrl + "/frontapi/getAllLaunchPad").then((res) => {
      var resp = res.data;
      setCoinTableData(resp.data);
      setcoinTableStatus(resp.status);
    });
  };

  const getRejectedByUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/getRejectedLaunchapad", {}, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status) {
          setLaunchapadRejectedData(resp.data);
        }
      });
  };

  function modifyText(text) {
    if (text.length <= 8) {
      return text;
    } else {
      return text.substr(0, 6);
      // return text.substr(0, 10) + "...";
    }
  }

  // filter to be added for completion, etc

  const formHtmlAll = () => {
    let html = [];
    if (coinTableStatus === true) {
      if (coinTableData.length > 0) {
        coinTableData.map(function (value, i) {
          let token = value.token_name.toUpperCase();
          return html.push(
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="launch-box">
                <div className="img-outer">
                  <a href={"/launchpadDetails/" + value.id}>
                    <img
                      src={baseUrl + "/static/launchpad/" + value.logo}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="content-outer">
                  <h5>
                    {token && token.length > 8
                      ? ["top"].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Tooltip id={`tooltip-${placement}`}>
                                <strong>{token}</strong>
                              </Tooltip>
                            }
                          >
                            <a>
                              {token.length > 0 && modifyText(token)}
                              ...
                            </a>
                          </OverlayTrigger>
                        ))
                      : !token
                      ? "-"
                      : token}
                    ({value.short_name.toUpperCase()})
                  </h5>
                  <p className="supply-total d-flex justify-content-between position-relative">
                    Session Supply: <b>{value.remaining_supply}</b>
                  </p>
                  <div className="start-end">
                    <p className="start text-success d-flex justify-content-between">
                      &#x1F551; Start <b>{value.ice_start_date}</b>
                    </p>
                    <p className="end text-danger d-flex justify-content-between">
                      &#x1F551; End <b>{value.ice_end_date}</b>
                    </p>
                  </div>
                  <div className="listing-outer">
                    <p>
                      Listing Confirmed |{" "}
                      <small className="text-danger">Bonus 10%</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
          // return html;
        });
      }
    } else {
      html.push(
        <div className="col-12">
          <div className="launch-box">
            <center>
              <h3>No Data</h3>
            </center>
          </div>
        </div>
      );
    }
    return html;
  };

  const formHtmlOngoing = () => {
    let html = [];
    if (coinTableStatus === true) {
      if (coinTableData.length > 0) {
        const result = coinTableData.filter(checkAdult);

        function checkAdult(data) {
          return (
            formattedDate >= data.ice_start_date &&
            data.ice_end_date >= formattedDate
          );
        }

        if (result.length > 0) {
          result.map(function (value, i) {
            let token = value.token_name.toUpperCase();
            return html.push(
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="launch-box">
                  <div className="img-outer">
                    <a href={"/launchpadDetails/" + value.id}>
                      <img
                        src={baseUrl + "/static/launchpad/" + value.logo}
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="content-outer">
                    <h4>
                      {token && token.length > 8
                        ? ["top"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  <strong>{token}</strong>
                                </Tooltip>
                              }
                            >
                              <a>
                                {token.length > 0 && modifyText(token)}
                                ...
                              </a>
                            </OverlayTrigger>
                          ))
                        : !token
                        ? "-"
                        : token}
                      ({value.short_name.toUpperCase()})
                    </h4>
                    <p className="supply-total d-flex justify-content-between position-relative">
                      Session Supply: <b>{value.remaining_supply}</b>
                    </p>
                    <div className="start-end">
                      <p className="start text-success d-flex justify-content-between">
                        &#x1F551; Start{" "}
                        <b className="text-white">{value.ice_start_date}</b>
                      </p>
                      <p className="end text-danger d-flex justify-content-between">
                        &#x1F551; End{" "}
                        <b className="text-white">{value.ice_end_date}</b>
                      </p>
                    </div>
                    <div className="listing-outer">
                      <p>
                        Listing Confirmed |{" "}
                        <small className="text-danger">Bonus 10%</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          html.push(
            <div className="col-12">
              <div className="launch-box">
                <center>
                  <h3>No Data</h3>
                </center>
              </div>
            </div>
          );
        }
      }
    } else {
      html.push(
        <div className="col-12">
          <div className="launch-box">
            <center>
              <h3>No Data</h3>
            </center>
          </div>
        </div>
      );
    }
    return html;
  };

  const formHtmlUpcoming = () => {
    let html = [];
    if (coinTableStatus === true) {
      if (coinTableData.length > 0) {
        const result = coinTableData.filter(checkAdult);

        function checkAdult(data) {
          return (
            data.ice_start_date > formattedDate &&
            data.ice_end_date > formattedDate
          );
        }
        if (result.length > 0) {
          result.map(function (value, i) {
            let token = value.token_name.toUpperCase();
            return html.push(
              <div className="col-md-3 col-sm-6 col-12">
                <div className="launch-box">
                  <div className="img-outer">
                    <a href={"/launchpadDetails/" + value.id}>
                      <img
                        src={baseUrl + "/static/launchpad/" + value.logo}
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="content-outer">
                    <h4>
                      {token && token.length > 8
                        ? ["top"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  <strong>{token}</strong>
                                </Tooltip>
                              }
                            >
                              <a>
                                {token.length > 0 && modifyText(token)}
                                ...
                              </a>
                            </OverlayTrigger>
                          ))
                        : !token
                        ? "-"
                        : token}
                      ({value.short_name.toUpperCase()})
                    </h4>
                    <p className="supply-total d-flex justify-content-between position-relative">
                      Session Supply: <b>{value.remaining_supply}</b>
                    </p>
                    <div className="start-end">
                      <p className="start text-success d-flex justify-content-between">
                        &#x1F551; Start{" "}
                        <b className="text-white">{value.ice_start_date}</b>
                      </p>
                      <p className="end text-danger d-flex justify-content-between">
                        &#x1F551; End{" "}
                        <b className="text-white">{value.ice_end_date}</b>
                      </p>
                    </div>
                    <div className="listing-outer">
                      <p>
                        Listing Confirmed |{" "}
                        <small className="text-danger">Bonus 10%</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          html.push(
            <div className="col-12">
              <div className="launch-box">
                <center>
                  <h3>No Data</h3>
                </center>
              </div>
            </div>
          );
        }
      }
    } else {
      html.push(
        <div className="col-12">
          <div className="launch-box">
            <center>
              <h3>No Data</h3>
            </center>
          </div>
        </div>
      );
    }

    return html;
  };

  const formHtmlCompleted = () => {
    let html = [];
    if (coinTableStatus === true) {
      if (coinTableData.length > 0) {
        const result = coinTableData.filter(checkAdult);

        function checkAdult(data) {
          return formattedDate > data.ice_end_date;
        }
        if (result.length > 0) {
          result.map(function (value, i) {
            let token = value.token_name.toUpperCase();
            return html.push(
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="launch-box">
                  <div className="img-outer">
                    <a href={"/launchpadDetails/" + value.id}>
                      <img
                        src={baseUrl + "/static/launchpad/" + value.logo}
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="content-outer">
                    <h4>
                      {" "}
                      {token && token.length > 8
                        ? ["top"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  <strong>{token}</strong>
                                </Tooltip>
                              }
                            >
                              <a>
                                {token.length > 0 && modifyText(token)}
                                ...
                              </a>
                            </OverlayTrigger>
                          ))
                        : !token
                        ? "-"
                        : token}
                      ({value.short_name.toUpperCase()})
                    </h4>
                    <p className="supply-total d-flex justify-content-between position-relative">
                      Session Supply: <b>{value.remaining_supply}</b>
                    </p>
                    <div className="start-end">
                      <p className="start text-success d-flex justify-content-between">
                        &#x1F551; Start{" "}
                        <b className="text-white">{value.ice_start_date}</b>
                      </p>
                      <p className="end text-danger d-flex justify-content-between">
                        &#x1F551; End{" "}
                        <b className="text-white">{value.ice_end_date}</b>
                      </p>
                    </div>
                    <div className="listing-outer">
                      <p>
                        Listing Confirmed |{" "}
                        <small className="text-danger">Bonus 10%</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          html.push(
            <div className="col-12">
              <div className="launch-box">
                <center>
                  <h3>No Data</h3>
                </center>
              </div>
            </div>
          );
        }
      }
    } else {
      html.push(
        <div className="col-12">
          <div className="launch-box">
            <center>
              <h3>No Data</h3>
            </center>
          </div>
        </div>
      );
    }
    return html;
  };

  const formHtmlRejected = () => {
    let html = [];

    if (launchapadRejectedData.length > 0) {
      launchapadRejectedData.map(function (value, i) {
        let token = value.token_name.toUpperCase();
        return html.push(
          <div className="col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="launch-box">
              <div className="img-outer">
                <a href={"#!"} onClick={(e) => e.preventDefault()}>
                  <img
                    src={baseUrl + "/static/launchpad/" + value.logo}
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="content-outer">
                <h4>
                  {" "}
                  {token && token.length > 8
                    ? ["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              <strong>{token}</strong>
                            </Tooltip>
                          }
                        >
                          <a>
                            {token.length > 0 && modifyText(token)}
                            ...
                          </a>
                        </OverlayTrigger>
                      ))
                    : !token
                    ? "-"
                    : token}
                  ({value.short_name.toUpperCase()})
                </h4>
                <p className="supply-total d-flex justify-content-between position-relative">
                  Session Supply: <b>{value.remaining_supply}</b>
                </p>
                <div className="start-end">
                  <p className="start text-success d-flex justify-content-between">
                    &#x1F551; Start{" "}
                    <b className="text-white">{value.ice_start_date}</b>
                  </p>
                  <p className="end text-danger d-flex justify-content-between">
                    &#x1F551; End{" "}
                    <b className="text-white">{value.ice_end_date}</b>
                  </p>
                </div>
                <div className="listing-outer">
                  <p>
                    Listing Rejected |{" "}
                    <small className="text-danger">Bonus 10%</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      html.push(
        <div className="col-12">
          <div className="launch-box">
            <center>
              <h3>No Data</h3>
            </center>
          </div>
        </div>
      );
    }

    return html;
  };

  const checkLogin = () => {
    if (!localStorage.getItem("token")) {
      toast.dismiss();
      toast.error("Please login to apply");
      return false;
    } else {
      toast.dismiss();
      window.location.href = "/launchPadForm";
    }
  };

  const getUrl = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.get(baseUrl + "/frontapi/getUrl", {}, config).then((res) => {
      var resp = res.data;

      setUrlLink(
        `${resp.data[0].firstCoinName}/${resp.data[0].secondCoinName}`
      );
    });
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        <header
          className="home position-relative launch-home mt-4 mt-md-5"
          id="home"
        >
          <div className="container">
            <div className="home-outer row launchpaid-outer launchpaid-off     align-items-center">
              <div className="col-md-6">
                <div className="banner-left">
                  <h1>LAUNCHPAD</h1>
                  <h2>
                    Unlock the future of finance with our exclusive crypto
                    exchange launchpad.
                  </h2>
                  <div>
                    Are you ready to discover the next big thing in the world of
                    digital assets? Our top-tier launchpad is the gateway to
                    revolutionary projects that could transform the crypto
                    landscape.
                  </div>

                  <div className="banner-btn-outer">
                    <button className="btn btn_man" onClick={checkLogin}>
                      Apply for Launchpad
                    </button>
                  </div>
                </div>
              </div>
              <div className="banner-right col-md-5 m-auto">
                <img
                  src="assets/img/launch-right.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </header>
        <div className="launch-main">
          <div className="container">
            <div className="launch-outer">
              <ul
                class="nav nav-tabs justify-content-center mb-4"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                  >
                    All
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                  >
                    Ongoing
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#tabs-3"
                    role="tab"
                  >
                    Upcoming
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#tabs-4"
                    role="tab"
                  >
                    Completed
                  </a>
                </li>
                {jwt && (
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#tabs-5"
                      role="tab"
                    >
                      Rejected
                    </a>
                  </li>
                )}
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="tabs-1" role="tabpanel">
                  <div className="launch-inner">
                    <div className="row">{formHtmlAll()}</div>
                  </div>
                </div>
                <div class="tab-pane" id="tabs-2" role="tabpanel">
                  <div className="launch-inner">
                    <div className="row">{formHtmlOngoing()}</div>
                  </div>
                </div>
                <div class="tab-pane" id="tabs-3" role="tabpanel">
                  <div className="launch-inner">
                    <div className="row">{formHtmlUpcoming()}</div>
                  </div>
                </div>
                <div class="tab-pane" id="tabs-4" role="tabpanel">
                  <div className="launch-inner">
                    <div className="row">{formHtmlCompleted()}</div>
                  </div>
                </div>
                <div class="tab-pane" id="tabs-5" role="tabpanel">
                  <div className="launch-inner">
                    <div className="row">{formHtmlRejected()}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="launch-faq p60 faqq ">
              <div className="section-heading">
                <h3>FAQ</h3>
              </div>
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1">
                    <a
                      href="#"
                      class=" btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      What is a crypto exchange launchpad?
                    </a>
                  </div>
                  <div
                    id="faq1"
                    class="collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div class="card-body">
                      <p>
                        It's a platform that allows promising crypto projects to
                        launch their tokens directly on our exchange. This gives
                        them exposure to our wide user base.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2">
                    <a
                      href="#"
                      class=" btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="false"
                      aria-controls="faq2"
                    >
                      Why should I participate in launchpad sales?
                    </a>
                  </div>

                  <div
                    id="faq2"
                    class="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    <div class="card-body">
                      <p>
                        Early access to vetted, high-quality projects means you
                        can get in on the ground floor. Think huge upside
                        potential if the project takes off!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class=" btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="false"
                      aria-controls="faq3"
                    >
                      How do I join an upcoming launchpad sale?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body">
                      <p>
                        It's simple - just complete the KYC process and reserve
                        your spot. Act fast, as seats fill up quickly for the
                        most anticipated launches.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default LaunchPad;
