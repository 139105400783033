import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Common/BaseUrl";
import { Link } from "react-router-dom";
const MarketNews = () => {
  const [refresh, setRefresh] = useState(false);
  const [record, setRecord] = useState([]);
  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = () => {
    axios
      .get(baseUrl + "/api/get-blog")
      .then((res) => {
        if (res.data.status) {
          setRecord(res.data.data);
        } else {
          setRecord([]);
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      {/* <div className="market-news-outer">
      <div className="container">
        <h2 className="text-center mb-4">Market News</h2>
       
        <div className="row">
        {record.map((list)=>{
          return (
            <div className="col-md-4">
            <div className="text-center">
            
               <div className="market-inner ">
                 <div className="img-outer">
                   <img
                     src={baseUrl+"/static/blog/"+list.image}
                     alt=""
                     className="img-fluid"
                   />
                 </div>
                 <div className="content-outer">
                   <h6>{list.title}</h6>
                  
                   <Link to={`/blog-detail/${list.id}`}>Read More</Link>
                 </div>
               </div>
              </div>
              </div>
          )
        })}
        
	
    </div>
      <div className="text-center"><Link to="/blog" className="btn btn_man pr-4 pl-4 mt-4">More</Link></div>
    </div>
    </div> */}
      <section className="faqq p60 " data-scroll-index={4}>
        <div className="container ">
          <h2 className="tc hadding">FAQ</h2>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-xs-12 m-auto">
              <div
                className="accordion md-accordion style-2"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div className="card">
                  <div className="card-header" role="tab" id="headingOne1">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      What is Rubiex Exchange?
                    </a>
                  </div>
                  {/* Card body */}
                  <div
                    id="collapseOne1"
                    className="collapse show "
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      Rubiex Exchange is a DeFi ecosystem that provides a
                      variety of financial tools and services, including DeFi
                      debit cards, smart payment gateways, P2P lending, and a
                      secure wallet. It aims to redefine how people conduct
                      financial transactions in the digital age.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo2">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo2"
                      aria-expanded="false"
                      aria-controls="collapseTwo2"
                    >
                      What is Rubiex Exchange?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo2"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Rubiex Exchange is a community-driven governance token for
                      the Rubiex Exchange platform, allowing token holders to
                      participate in decision-making processes and help shape
                      the future of the ecosystem.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo4">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo4"
                      aria-expanded="false"
                      aria-controls="collapseTwo4"
                    >
                      When and where will I receive my Rubiex Exchange?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo4"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      You will be able to claim your Rubiex Exchange after the
                      presale concludes. This claiming process will take place
                      on Retik's official website.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo5">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo5"
                      aria-expanded="false"
                      aria-controls="collapseTwo5"
                    >
                      How many presale stages are there?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo5"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Rubiex Exchange presale consists of 10 stages. For
                      additional details, please visit: https://rubiex.io/
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo6">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo6"
                      aria-expanded="false"
                      aria-controls="collapseTwo6"
                    >
                      When and where will Guyana launch?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo6"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Following the presale, Rubiex Exchange will launch on
                      multiple centralized exchanges, including at least two
                      top-tier exchanges.Stay tuned for official announcements
                      about the specific launch date and trading platforms by
                      following our social media channels.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo7">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo7"
                      aria-expanded="false"
                      aria-controls="collapseTwo7"
                    >
                      When can I apply for my Guyana DeFi Debit Card?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo7"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Retik's DeFi debit cards will be available for pre-order
                      once our app is live on the App Store and Google Play
                      Store. For more information download ourwhitepaper.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo8">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo8"
                      aria-expanded="false"
                      aria-controls="collapseTwo8"
                    >
                      My wallet is compromised/hacked, what should I do?
                    </a>
                  </div>
                  <div
                    id="collapseTwo8"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      If you suspect that your wallet has been compromised, it's
                      essential to take immediate action to protect your assets.
                      Please follow these steps: Never Share Your Seed Phrase or
                      Private Key: Under no circumstances should you share your
                      Seed Phrase or Private Key with anyone. These are
                      sensitive and should be kept secure.
                      <br />
                      Contact Us: Reach out to us at: https://rubiex.io/
                      Exchange.com/help to report the issue and seek guidance on
                      how to proceed. Important Note:While we can assist you in
                      claiming Rubiex Exchange in the unfortunate event of your
                      wallet being compromised or hacked, it's crucial to
                      understand that we cannot recover lost funds. Protecting
                      your assets remains a top priority.
                      <br />
                      Beware of Scammers:Remember that we will never ask for
                      your private details, such as your Seed Phrase or Private
                      Key. Be cautious of potential scams and always verify the
                      authenticity of the support you're engaging with.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MarketNews;
