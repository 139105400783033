import React from "react";

export const EmailValidation = (name,value) => {
let emailAddressError = "";
value = value.toLowerCase()
if (!value) {
    emailAddressError = "This field  is required";
    return emailAddressError
}
// var Email1 = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
//     value
// );
var Email1 = /^[A-Z0-9][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    value
);
if (!Email1) {
    emailAddressError = "Please enter a valid email address";
    return emailAddressError
}
// if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
//     emailAddressError = "Please enter a valid email address";
//     return emailAddressError
// }
if (value.replace(/\s+/g, '').length == 0) {
    emailAddressError = "Please enter a valid email address"
    return emailAddressError
}
return emailAddressError
}
