import React from "react";
import BigNumber from "bignumber.js";

const Ticker = (props) => (
  <ul>
    <li>
      <h4 className="ng-binding">Last Price</h4>
      <strong className="ng-binding">
        <span
          className={
            new BigNumber(props.c).gte(new BigNumber(props.lastc))
              ? ""
              : "text-danger"
          }
        >
          {isNaN(props.c) ? "0.00":new BigNumber(props.c).toFormat(null, 1)}
        </span>
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">24h-Change</h4>
      <strong>
        {" "}
        {isNaN(props.p)?"0.00":new BigNumber(props.p).toFormat(null, 1)}{" "}
        {isNaN(props.P)?"0.00":new BigNumber(props.P).toFormat(null, 1)}%
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">24h-High</h4>
      <strong className="ng-binding">
        {isNaN(props.h)?"0.00": new BigNumber(props.h).toFormat(null, 1)}
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">24h-Low</h4>
      <strong className="ng-binding">
        {isNaN(props.l)?"0.00": new BigNumber(props.l).toFormat(null, 1)}
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">24h Volume</h4>
      <strong className="ng-binding">
        {!isNaN(props.q)
          ? `${new BigNumber(props.q).toFormat(2, 1)}`
          : `0.00`}
      </strong>
    </li>
  </ul>
);

export default Ticker;
