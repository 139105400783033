import React from "react";
import { Component } from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";

class PrivacyPolicy extends Component {
  render() {
    const meta = {
      title: `Privacy Policy | ${ProductName}  Exchange`,
      description:
        "High Limit Crypto Exchange For Crypto Currencies Like  TRX, DOGE, BTC, ETH And Many More. Download The App For A Secure Crypto Trading Platform In India.",
      canonical: `https://${ProductName} exchange.in/secure-crypto-exchange-platform`,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Low Cost Crypto App, Crypto Coin Exchange, Best Rate Crypto Exchange, Crypto Platform ",
        },
      },
    };
    return (
      <div>
        <DocumentMeta {...meta}>
          <div>
            <MainHeader />

            <div className="privacy_policy">
              <div className="container">
                <h2>{ProductName}  Exchange Privacy Policy</h2>
                <p>
                  This is the Privacy Policy for {ProductName}  Exchange. {ProductName} 
                  Exchange will be referred to as "{ProductName}  Exchange", "we", "our"
                  and "us" in this Privacy Policy.
                  <br />
                  The Privacy Policy relates to how we collect, use, share and
                  secure the personal information that you may supply to us. It
                  also describes your choices regarding use, access and
                  correction of your personal information.
                  <br />
                  We recognise the importance of protecting the privacy of
                  information collected by us, in particular, information that
                  is capable of identifying an individual ("personal
                  information"). This Privacy Policy governs the manner in which
                  your personal information will be dealt with. {ProductName}  Exchange
                  is the entity collecting and holding your personal information
                  in accordance with this Privacy Policy. We reserve the right,
                  at our discretion, to modify or remove portions of this
                  Privacy Policy at any time.
                  <br />
                  {ProductName}  Exchange is responsible for the processing of personal
                  data that it receives under the India Privacy Act 2001, and
                  other applicable international laws.
                  <br />
                  Please take a moment to read this Privacy Policy carefully. If
                  you have any questions about this Privacy Policy, please
                  contact us at&nbsp;support@{ProductName} exchangeexchange.org. You
                  should review this Privacy Policy periodically so that you are
                  updated on any changes.
                </p>
                <h4>{ProductName}  Exchange Privacy Policy</h4>
                <p>
                  By using this website, you agree to the Privacy Policy of
                  {ProductName}  Exchange, which is set out on this website page. This
                  Privacy Policy is in addition to any other terms and
                  conditions applicable to the website. This website may contain
                  links to other websites not operated or controlled by us, and
                  we do not make any representations about such third-party
                  websites.
                </p>
                <h4>Collection of Information</h4>
                <p>
                  We may collect personal information about you through your
                  conduct on our website, via support services, email,
                  telephone, any contact made by you through social media
                  channels and through any other interaction you may have with
                  us.
                  <br />
                  We collect and hold personal information about, but not
                  limited to:
                  <br />
                  a. users and potential users <br />
                  b. business associates and potential business associates, and
                  their employees and contractors (where applicable) <br />
                  c. individuals we deal with in the course of providing
                  services to our users <br />
                  d. suppliers and their employees and contractors <br />
                  e. employees, prospective employees and contractors <br />
                  f. other people who come into contact with us.
                </p>
                <h4>Collection of Information</h4>
                <p>
                  We may collect personal information about you through your
                  conduct on our website, via support services, email,
                  telephone, any contact made by you through social media
                  channels and through any other interaction you may have with
                  us.
                  <br />
                  We collect and hold personal information about, but not
                  limited to: a. users and potential users; b. business
                  associates and potential business associates, and their
                  employees and contractors (where applicable);
                </p>
                <p>
                  We collect personal information about you in order for us to
                  provide you with our services, for you to take full advantage
                  of our services, and for us to conduct our business and meet
                  our legal obligations.
                </p>
                <p>
                  Personal information is collected when provided by you, via
                  the website, email, support, phone, social media, or any other
                  interactions you may have with us. For example, we may need to
                  collect such information to provide you with services or to
                  answer or forward any requests or enquiries that you submit to
                  us. Information we collect may include (but is not limited
                  to): names, addresses, contact details, occupations, payment
                  details, employment history, education and qualifications,
                  testimonials, feedback and other information which assists us
                  in conducting our business, providing and marketing our
                  services, and meeting our legal obligations.
                </p>
                <p>
                  <b>
                    We may also obtain information from other sources and
                    combine that with information we collect through our
                    services. For example, we may collect information about you
                    from third parties, including but not limited to social
                    media platforms and publicly available sources.
                  </b>
                </p>
                <p>
                  Registration with the website is voluntary. Registration may
                  include submitting (for instance, but not limited to) your
                  name, email address, address, telephone numbers, and selecting
                  an option to receive updates and promotional material and
                  other information. You may view and edit this information at
                  any time by logging in and going to your account.
                </p>
                <p>
                  We may also collect information about you as permitted by law.
                </p>
                <p>
                  In some cases, if personal information we request is not
                  provided, it might adversely impact our ability to supply you
                  with services or perform our obligations to you.
                </p>
                <p>
                  Any information you provide to us that is not required is
                  voluntary. You are free to choose whether to provide us with
                  the types of personal information requested, but we may not be
                  able to serve you as effectively or offer you Services when
                  you do choose not to share certain information with us.
                </p>
                <strong>
                  Collection and Use of Information Collected Automatically
                </strong>
                <p>
                  We receive and store certain types of information
                  automatically, such as whenever you interact with the sites or
                  use our services. This information does not necessarily reveal
                  your identity directly but may include information about the
                  specific device you are using, such as the hardware model,
                  device ID, operating system version, web-browser software
                  (such as Firefox, Safari, or Internet Explorer) and your
                  Internet Protocol (IP) address/MAC address/device identifier.
                </p>
                <p>
                  When you visit our website, our web servers gather your IP
                  address to assist with the diagnosis of problems and to enable
                  us to provide the best level of service.
                </p>
                <p>
                  We use cookies on our website to provide you with a better
                  experience. These cookies allow us to increase your security
                  by storing your session ID and are a way of monitoring single
                  user access. This aggregate, non-personal information is
                  collated and provided to us to assist in analysing the usage
                  of the site.
                </p>
                <strong>Management of Personal Information</strong>
                <p>
                  We strive to ensure the security, integrity and privacy of
                  personal information submitted to us, and we regularly review
                  and update our security measures in light of current
                  {ProductName} logies. While no data transmission over the Internet can
                  be guaranteed to be totally secure, we will endeavour to take
                  all reasonable steps to ensure the security, on our systems,
                  of the personal information you transmit to us via our online
                  products and services.
                  <br />
                  In addition, our employees and the contractors who provide
                  services related to our information systems are obliged to
                  respect the confidentiality of any personal information held
                  by us.
                </p>
                <strong>Use of Information</strong>
                <p>
                  Unless we disclose other uses in this Privacy Policy or at the
                  time of collection, personal information about you is used
                  only for the purpose for which it is submitted or for such
                  other secondary purposes that are related to the primary
                  purpose. In general, the purposes for which your personal
                  information is collected, used, held and disclosed are: <br />
                  a. to conduct our business <br />
                  b. to provide you with our services and any other services you
                  request <br />
                  c. to market our services to you <br />
                  d. to engage with third parties on your behalf <br />
                  e. to communicate with you <br />
                  f. to help us manage and enhance our services <br />
                  g. to comply with our legal obligations
                </p>
                <p>
                  Copies of correspondence sent by you, or from the website that
                  may contain your personal information, are stored as archives
                  for record-keeping and back-up purposes only. All
                  communication from us (including information with regards to
                  new products and services) will provide you with an
                  opportunity to opt out of further communication. You can
                  choose to opt out at any time from receiving marketing
                  communications.
                  <br />
                  We do not allow the selling of your personal information in
                  any way.
                </p>
                <strong>Retention and Disclosure of Information</strong>
                <p>
                  We take care to allow your personal information to be accessed
                  only by those employees who require it in order to perform
                  their tasks and duties, and to share with third parties who
                  have a legitimate purpose for accessing it. <br />
                  We may disclose your personal information
                  <br />
                  a. where you have consented
                  <br />
                  b. where disclosure is necessary to achieve the purpose for
                  which the personal information was submitted
                  <br />
                  c. when the law permits or requires disclosure
                </p>
                <p>
                  We may also disclose your personal information in special
                  situations where we have reason to believe that doing so is
                  necessary to identify, contact or bring legal action against
                  anyone damaging, injuring, or interfering with (intentionally
                  or unintentionally) our rights or property, users, or anyone
                  else who could be harmed by such activities.
                </p>
                <p>
                  We may engage third parties to support our operations and/or
                  provide you with services on our behalf (such as identity
                  verification agencies, credit reporting agencies, and
                  outsourced IT service providers). Where we deem it necessary
                  we may disclose personal information held by us to these third
                  parties solely for the purpose of, and in connection with,
                  performing the particular service or function that we have
                  engaged the third party to provide.
                </p>
                <p>
                  <b>
                    We generally retain personal data for so long as it may be
                    relevant to the purposes for which it was obtained. To
                    dispose of personal data, we may anonymise it, delete it or
                    take other appropriate steps.
                  </b>
                </p>
                <p>
                  Where we no longer require your personal information, we will
                  comply with our legal requirements in respect of that
                  information.
                </p>
                <strong>Access to Information</strong>
                <p>
                  We will endeavour to take all reasonable steps to keep secure
                  any information which we hold about you, and to keep this
                  information accurate and up to date.
                </p>
                <p>
                  The majority of the information that we hold about you will be
                  available for you to review by logging into your online
                  account with us.
                </p>
                <p>
                  Subject to the exceptions set out in the Privacy Act 2001, you
                  may seek access to the personal information that we hold about
                  you. We will require you to verify your identity, and to
                  specify what information you require. A fee may be charged for
                  providing access. If a fee is to be charged, we will advise
                  you of the likely cost in advance.
                </p>
                <p>
                  If, at any time, you discover that information held about you
                  is incorrect, you may contact us to request to have the
                  information corrected in accordance with the Privacy Act 2001.
                </p>
                <strong>Links to External Websites</strong>
                <p>
                  We provide links to websites outside of our websites, as well
                  as to third party websites. These linked sites are not under
                  our control, and we cannot accept responsibility for the
                  conduct of companies linked to our website. Before disclosing
                  your personal information on any other website, we advise you
                  to examine the terms and conditions of using that website and
                  its privacy statement.
                </p>
                <strong>Legal bases for processing personal information</strong>
                <p>
                  The laws in some jurisdictions require companies to tell you
                  about the legal ground they rely on to process, use or
                  disclose your personal data. To the extent those laws apply,
                  our legal grounds are as follows:
                </p>
                <p>
                  {" "}
                  a. you consent to such processing
                  <br />
                  b. such processing is required to satisfy our legal
                  obligations
                  <br />
                  c. such processing is necessary in order to carry out our
                  obligations as part of any contracts we may have with you, or
                  as part of negotiations before entering into a contract with
                  you
                  <br />
                  d. such processing is required in order for us to protect our
                  property and rights, or the safety of {ProductName}  Exchange, our
                  users, contractors, or other affiliates.
                </p>
                <h4>Direct Marketing</h4>
                <p>
                  If you are a current user, we will only contact you by
                  electronic means (email or SMS) with information regarding any
                  services which are similar or related to those in which you
                  have previously expressed an interest, be that through
                  transaction history or enquiry.
                </p>
                <p>
                  <b>
                    If you are a new user, we will only contact you by
                    electronic means for marketing purposes if you have
                    consented to receive such communications. Direct marketing
                    includes any communications to you regarding advertising or
                    the promotion of products and services. If you do not want
                    to receive marketing communications, at any time and free of
                    charge, you may use the opt-out link provided in any
                    communication from us, or contact us at&nbsp;support@{ProductName} 
                    Exchangeexchange.org.
                  </b>
                </p>
                <h4>Individual Rights</h4>
                <p>
                  We believe that users should be treated equally no matter
                  where they are, and so we are making the following options to
                  control your data available to all users regardless of their
                  location.
                </p>
                <p>
                  <b>
                    You can update certain information by logging into your
                    account with us and accessing your account information and
                    settings. You can also contact us at&nbsp;support@{ProductName} 
                    Exchangeexchange.org&nbsp;so that we may consider your
                    request under applicable law.
                  </b>
                </p>
                <p>
                  <b>
                    We encourage you to make any individual rights requests
                    through our user support process (https://{ProductName} exchangeexchange.org/support) 
                    because this ensures that you
                    have been authenticated already. Otherwise, when we receive
                    an individual rights request via e-mail we may take steps to
                    verify your identity before complying with the request to
                    protect your privacy and security.
                  </b>
                </p>
                <p>
                  <strong>Right to withdraw consent.</strong> You have the right
                  to withdraw your consent to our processing of your personal
                  information collected on the basis of your consent at any
                  time. Please note that withdrawing this consent does not
                  affect the lawfulness of processing we may have undertaken
                  prior to the withdrawal of consent. The withdrawal of consent
                  will not affect the lawfulness of processing of information
                  for which consent is not required.
                </p>
                <p>
                  <strong>
                    Right of access to and rectification of your personal
                    information.
                  </strong>{" "}
                  You have the right to request that we provide you with a copy
                  of all personal information pertaining to you that we hold.
                  This information will be provided without undue delay, unless
                  such provision adversely affects the rights and freedoms of
                  other users, contractors or affiliates of {ProductName}  Exchange. If
                  a fee is to be charged for this service, the full amount will
                  be made known to you in advance. You may also request that we
                  rectify any false personal information pertaining to you that
                  we keep on record. Your right to access and rectification
                  shall only be limited where the expense of providing such
                  access becomes disproportionate to the risks to your privacy,
                  or where the rights of other users, contractors or affiliates
                  may be violated.
                </p>
                <p>
                  <strong>Right to erasure.</strong> You have the right to
                  request that we erase personal information that pertains to
                  you if that information:
                  <br />
                  a. is no longer necessary in relation to the purposes for
                  which it was collected
                  <br />
                  b. was collected in relation to processing to which you had
                  previously consented, but later withdrew such consent
                  <br />
                  c. was collected in relation to processing activities to which
                  you object, and there are no legitimate overriding grounds for
                  such activities.
                </p>
                <p>
                  If we have made your personal information public and are
                  obliged to erase that information, we will take reasonable
                  steps to inform relevant third parties that are processing
                  your personal information that you have requested the erasure,
                  including all links to, or copies or replications of, your
                  personal information. The above is subject to limitations by
                  relevant data protection laws
                </p>
                <p>
                  <strong>Right to data portability.</strong> You have the right
                  to request that we make available any personal information
                  pertaining to you, which we obtained through a contract with
                  you or based on your consent, in a structured, commonly-used
                  and machine-readable format. You may also request that we
                  transfer this information directly to another "controller"
                  where technically feasible, unless the exercise of this right
                  adversely affects the rights and freedoms of others. A
                  "controller" is a natural or legal person, public authority,
                  agency, or other body which, alone or jointly with others,
                  determines the purposes and means of the processing of your
                  personal information.
                </p>
                <p>
                  <strong>Right to restrict or object to processing.</strong>{" "}
                  You have the right to restrict or object to us processing your
                  personal information where one of the following applies:
                </p>
                <p>
                  a. You contest the accuracy of your personal information that
                  we processed. In such instances, we will restrict processing
                  during the period necessary for us to verify the accuracy of
                  your personal information
                  <br />
                  b. The processing is unlawful and you oppose the erasure of
                  your personal information and request the restriction of its
                  use instead
                  <br />
                  c. We no longer need your personal information for the
                  purposes of the processing, but it is required by you to
                  establish, exercise, or defend legal claims.
                  <br />
                  d. You have objected to processing, pending the verification
                  whether the legitimate grounds of our processing override your
                  rights.
                  <br />
                  Restricted personal information shall only be processed with
                  your consent or for the establishment, exercise or defence of
                  legal claims or for the protection of the rights of another
                  natural or legal person or for reasons of important public
                  interest. We will inform you if the restriction is lifted.
                </p>
                <p>
                  <strong>
                    {" "}
                    Notification of erasure, rectification and restriction.
                  </strong>
                  &nbsp;We will communicate any rectification or erasure of your
                  personal information or restriction of processing to each
                  recipient to whom your personal information has been
                  disclosed, if any, unless this proves impossible or involves
                  disproportionate effort. We will inform you about those
                  recipients if you request this information.
                </p>
                <p>
                  <strong>Right to object to processing.</strong> Where the
                  processing of your personal information is based on consent,
                  contract or legitimate interests you may restrict or object,
                  at any time, to the processing of your personal information as
                  permitted by applicable law. We can continue to process your
                  personal information if it is necessary for the defence of
                  legal claims, or for any other exceptions permitted by
                  applicable law.
                </p>
                <p>
                  <strong>
                    Automated individual decision-making, including profiling.
                  </strong>{" "}
                  You have the right not to be subject to a decision based
                  solely on automated processing of your personal information,
                  including profiling, which produces legal or similarly
                  significant effects on you, save for the exceptions applicable
                  under relevant data protection laws.
                </p>
                <p>
                  <strong>Right to lodge a complaint.</strong> If you believe
                  that we have infringed your rights, we encourage you to
                  contact us first at&nbsp;info@{ProductName} exchangeexchange.org&nbsp;
                  so that we can try to resolve the
                  issue or dispute. You can also complain about our processing
                  of your personal information to the relevant data protection
                  authority. If you are a resident of the European Economic
                  Area, you can complain in the EU member state where you live
                  or work, or in the place where the alleged breach of data
                  protection law has taken place.
                </p>
                <p>
                  <strong>Data Protection Officer.</strong> &nbsp;You may reach
                  the {ProductName}  Exchange Data Protection Officer
                  at&nbsp;info@{ProductName} exchangeexchange.org.<br />
                  <strong>Your rights to personal information.</strong> Your
                  rights to personal information are not absolute. Access may be
                  denied when:
                  <br />
                  a. Denial of access is required or authorised by law
                  <br />
                  b. Granting access would have a negative impact on other's
                  privacy
                  <br />
                  c. To protect our rights and properties
                  <br />
                  d. Where the request is deemed frivolous or vexatious.
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
          <Footer />
        </DocumentMeta>
      </div>
    );
  }
}
export default PrivacyPolicy;
