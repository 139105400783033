import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./components/Homepage/Index";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";
import Security from "./components/SettingsFile/Security";
import Kyc from "./components/Kyc/index";
import Forgot from "./components/Login/Forgot";
import ResetPassword from "./components/Login/LoginElement/ResetPassword";
import ApiKeys from "./components/SettingsFile/ApiKeys";
import help from "./components/help";
import chatting from "./components/chatting";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RiskStatement from "./components/RiskStatement";
import TermsOfConditions from "./components/TermsOfConditions";
import AboutUs from "./components/AboutUs";
import faq from "./components/faq";
import { history } from "./helpers/history";
import NotFound from "./components/NotFound";
import transactions from "./components/SettingsFile/transactions";
import spot from "./components/SettingsFile/spot";
import Auth from "./components/Login/Auth";
import EmailAuth from "./components/Login/EmailAuth";
import CoinWithdrawalUsers from "./components/SettingsFile/CoinWithdrawalUser";
import MobileChart from "./components/SettingsFile/MobileChart";
import SpotSell from "./components/SettingsFile/SpotSell";
import DepositeHistory from "./components/SettingsFile/DepositeHistory";
import CoinDepositeUser from "./components/SettingsFile/CoinDepositeUser";
import Swap from "./components/SettingsFile/Swap";
import Profile from "./components/SettingsFile/Profile";
import Send from "./components/Send";
import whiteList from "./components/WhiteList/index";
import ExchnageFunc from "./components/ExchangeFull/ExchngeFunc";
import ExchangeSocket from "./components/ExchangeFull/ExchangeSocket";
import Blog from "./components/blog/Blog";
import BlogDetail from "./components/blog/BlogDetail";
import RejectVerification from "./components/verifypages/RejectVerification";
import ApproveVerification from "./components/verifypages/ApproveVerification";
import ApprovePayment from "./components/verifypages/ApprovePayment";
import ComingSoon from "./components/Homepage/HomepageElements/ComingSoon";
import Stacking from "./components/SettingsFile/Stacking";
import MyStacking from "./components/SettingsFile/MyStacking";
import LaunchPad from "./components/SettingsFile/LaunchPad";
import Listing from "./components/SettingsFile/Listing";
import ApplyListing from "./components/SettingsFile/ApplyListing";
import LaunchPadForm from "./components/SettingsFile/LaunchPadForm";
import LaunchPadDetails from "./components/SettingsFile/LaunchPadDetails";
import Refer from "./components/SettingsFile/Refer";

const App = () => {
  useEffect(() => {
    var token = localStorage.getItem("token");
    if (token) {
      if (
        window.location.pathname === "/register-for-bitcoin-exchange" ||
        window.location.pathname === "/login-to-buy-sell-crypto" ||
        window.location.pathname === "/forgot"
      ) {
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={["/", "/index"]} component={ComingSoon} />
        <Route exact path={"/demo"} component={Index} />
        <Route exact path="/login-to-buy-sell-crypto" component={Login} />
        <Route path="/register-for-bitcoin-exchange/:id" component={Register} />
        <Route path="/register-for-bitcoin-exchange" component={Register} />
        <Route exact path="/" component={Index} />
        <Route path="/account" component={Account} />
        <Route path="/whitelist" component={whiteList} />
        <Route path="/security" component={Security} />
        <Route path="/kyc" component={Kyc} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/reset/:userId/:token" component={ResetPassword} />
        <Route path="/ApiKeys" component={ApiKeys} />
        <Route path="/help" component={help} />
        <Route path="/faq-for-crypto-exchange" component={faq} />
        <Route path="/Chatting/:coin/:status" component={chatting} />
        <Route path="/contact-us-for-crypto-exchange" component={ContactUs} />
        <Route
          path="/secure-crypto-exchange-platform"
          component={PrivacyPolicy}
        />
        <Route path="/crypto-buying-selling-online" component={RiskStatement} />
        <Route
          path="/terms-&-conditions-of-crypto-exchange"
          component={TermsOfConditions}
        />
        <Route path="/buy-sell-crypto-online" component={AboutUs} />
        <Route
          path="/exchange/:firstCoin/:secondCoin"
          component={ExchangeSocket}
        />
        <Route path="/exchange/" component={ExchnageFunc} />
        <Route path="/transactions" component={transactions} />

        <Route path="/spot" component={spot} />
        <Route path="/coinFundRequest" component={CoinWithdrawalUsers} />
        <Route path="/GoogleAuthLogin" component={Auth} />
        <Route path="/Swap" component={Swap} />
        <Route path="/emailAuth" component={EmailAuth} />
        <Route
          path="/MobileChart/:firstCoin/:secondCoin"
          component={MobileChart}
        />
        <Route path="/spotSellHistory" component={SpotSell} />
        <Route path="/depositeHistory" component={DepositeHistory} />
        <Route path="/coinDeposite" component={CoinDepositeUser} />
        <Route exact path="/stacking" component={Stacking} />
        <Route exact path="/myStacking" component={MyStacking} />
        <Route exact path="/launchPad" component={LaunchPad} />

        <Route exact path="/launchPadForm" component={LaunchPadForm} />
        <Route exact path="/refer" component={Refer} />
        <Route
          exact
          path="/launchPadDetails/:id"
          component={LaunchPadDetails}
        />
        <Route exact path="/listing" component={Listing} />
        <Route exact path="/applyListing" component={ApplyListing} />
        <Route path={"/profile"} component={Profile} />
        <Route path={"/payment"} component={Send} />
        <Route path={"/blog"} component={Blog} />
        <Route path={"/blog-detail/:id"} component={BlogDetail} />
        <Route
          path={"/rejectVerification/:id"}
          component={RejectVerification}
        />
        <Route
          path={"/approveVerification/:id"}
          component={ApproveVerification}
        />
        <Route path={"/approvePayment/:id"} component={ApprovePayment} />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
