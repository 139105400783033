import React, { useEffect, useState } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import Footer from "../Common/Footer";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Nav from "../Common/Nav";
import DocumentMeta from "react-document-meta";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;

const meta = {
  title: "Listings | rubiex  Exchange ",
  description:
    "rubiex  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://rubiex  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Listing = () => {
  const [tableStatus, setTableStatus] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [limitStatus, setLimitStatus] = useState(false);
  const [limitData, setLimitData] = useState([]);
  const [isKyc, setIsKyc] = useState("N");

  useEffect(() => {
    getUserKycInfo();
    getData();
  }, []);
  const getData = () => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.get(baseUrl + "/frontapi/allListings", {}, config).then((res) => {
      var resp = res.data;

      // Get the first 5
      const firstFive = resp.data.slice(0, 5);

      setTableData(resp.data);
      setTableStatus(resp.status);

      setLimitData(firstFive);
      setLimitStatus(resp.status);
    });
  };

  const checkLogin = () => {
    const token = localStorage.getItem("token")
    console.log("token",token)
    if (!token) {
      toast.dismiss();
      toast.error("Please login to apply");
      return false;
    } else {
      if (isKyc !== "Y") {
        toast.dismiss();
        toast.error("Please complete your KYC first");
        return false;
      }
      toast.dismiss();
      window.location.href = "/applyListing";
    }
  };

  const getUserKycInfo = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      const resp = res.data;
      setIsKyc(resp.data[0].kycVerification_status);
    });
  };

  return (
    <div>
      {" "}
      <DocumentMeta {...meta}>
        <Nav graphColor="" />
        {/* <MainHeader /> */}

        <header
          className="home position-relative launch-home mt-4 mt-md-5"
          id="home"
        >
          <div className="container">
            <div className="home-outer row launchpaid-outer launchpaid-off     align-items-center">
              <div className="col-md-6">
                <div className="banner-left">
                  <h1>LISTING</h1>
                  <h2>
                    Looking to get your crypto project noticed? Our exchange is
                    the perfect platform to showcase your digital assets and
                    reach a wider audience.
                  </h2>
                  <div>
                    With our robust security measures and user-friendly
                    interface, you can list your crypto with confidence. Gain
                    exposure to our growing community of crypto enthusiasts and
                    investors hungry for the next big thing.
                  </div>

                  <div className="banner-btn-outer">
                    {/* <a href='javascript:void(0)' className='btn btn-primary'>Trade Now</a> */}

                    <button className="btn btn_man" onClick={checkLogin}>
                      Apply for Listing
                    </button>
                  </div>
                </div>
              </div>
              <div className="banner-right col-md-5 m-auto">
                <img
                  src="assets/img/listing-right.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </header>

        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};
export default Listing;
