import React from "react";
import axios from "axios";
import * as myConstList from "../Common/BaseUrl";
import { Link } from "react-router-dom";

const baseUrl = myConstList.baseUrl;
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstInterval: null,
    };
    // this.afxInterval = null;
    this.dotInterval = null;
    this.dogeInterval = null;
    this.xrpInterval = null;
    this.adaInterval = null;
    this.vetInterval = null;
    this.bttcInterval = null;

    window.scrollTo(0, 0);
  }

  GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (
        resp.key === "logout" &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null
      ) {
        localStorage.clear();
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      }
    });
  };

  componentWillMount = () => {
    var token = localStorage.getItem("token");
    if (token) {
      // this.timerId();
      // this.timerId1();
      // this.timerId2();
      // this.timerId3();
      // this.timerId4();
      // this.timerId5();
      // this.timerId6();
      // this.timerId7();
      this.GetUserData();
    }
  };
  timerId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-afx", {}, config).then((res) => {
      var resp = res.data;
      if (!this.afxInterval) {
        this.afxInterval = setInterval(() => {
          this.timerId();
        }, 600000);
      }
    });
  };

  timerId1 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-dot", {}, config).then((res) => {
      var resp = res.data;
      if (!this.dotInterval) {
        this.dotInterval = setInterval(() => {
          this.timerId1();
        }, 600000);
      }
    });
  };

  timerId2 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-doge", {}, config).then((res) => {
      var resp = res.data;
      if (!this.dogeInterval) {
        this.dogeInterval = setInterval(() => {
          this.timerId2();
        }, 600000);
      }
    });
  };

  timerId3 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-xrp", {}, config).then((res) => {
      var resp = res.data;
      if (!this.xrpInterval) {
        this.xrpInterval = setInterval(() => {
          this.timerId3();
        }, 600000);
      }
    });
  };

  timerId4 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ada", {}, config).then((res) => {
      var resp = res.data;
      if (!this.adaInterval) {
        this.adaInterval = setInterval(() => {
          this.timerId4();
        }, 600000);
      }
    });
  };

  timerId5 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-vet", {}, config).then((res) => {
      var resp = res.data;
      if (!this.vetInterval) {
        this.vetInterval = setInterval(() => {
          this.timerId5();
        }, 600000);
      }
    });
  };

  timerId6 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-bttc", {}, config).then((res) => {
      var resp = res.data;
      if (!this.this.bttcInterval) {
        this.this.bttcInterval = setInterval(() => {
          this.timerId6();
        }, 600000);
      }
    });
  };

  timerId7 = () => {
    axios.get(baseUrl + "/frontapi/depositbtctransaction").then((LiveResp) => {
      var resp = LiveResp.data;
      if (resp.status === true) {
        setTimeout(() => {
          this.timerId7();
        }, 600000);
      }
    });
  };
  reloadPage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section className="contact footer" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <img
                src="assets/img/logo.png"
                alt="header-Logo"
                className="logo "
              />

              <p>
                Rubiex Crypto Exchange is India's one of the most crypto
                exchange platforms in India where you can buy or sell Bitcoin,
                Ethereum, Tron, Ripple and various other cryptocurrencies.
              </p>
              <div className="footer-social  mb-4">
                <div className="vertical-social wow fadeInDown  animated">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="https://x.com/rubiexexchange"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/rubiex_exchange"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@Rubiex_Exchange"
                        rel="noreferrer"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/rubiex-exchange-39a701332/"
                        rel="noreferrer"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://t.me/RubiexExchange"
                        rel="noreferrer"
                      >
                        <i className="fa fa-send"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-4 col-6 ml-auto">
              <div className="nav_link">
                <h5>About</h5>
                <ul className="">
                  <li className="">
                    <Link className="" to="">
                      Careers
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="">
                      Announcements
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="">
                      Media Assets
                    </Link>
                  </li>

                  <li className="">
                    <Link className="" to="">
                      Terms Of Use
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="">
                      News
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="/buy-sell-crypto-online">
                      About Us
                    </Link>
                  </li>
                  <li className="">
                    <Link className="" to="/contact-us-for-crypto-exchange">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Services</h5>
                <ul>
                  <li>
                    <Link to="">Download</Link>
                  </li>
                  <li>
                    <Link to="">Corporate Account</Link>
                  </li>
                  <li>
                    <Link to="">Referral Program</Link>
                  </li>
                  <li>
                    <Link to="">List Your Coin</Link>
                  </li>

                  <li>
                    <Link to="/secure-crypto-exchange-platform">
                      Privacy & Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/crypto-buying-selling-online">
                      Risk Statement
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-&-conditions-of-crypto-exchange">
                      Terms & Condition
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
              <div className="nav_link">
                <h5>Support</h5>
                <ul>
                  <li>
                    <Link to="">Help Center</Link>
                  </li>
                  <li>
                    <Link to="">Fees</Link>
                  </li>
                  <li>
                    <Link to="">Security</Link>
                  </li>
                  <li>
                    <Link to="">Law Enforcement</Link>
                  </li>
                  <li>
                    <Link to="/faq-for-crypto-exchange">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright tc">
          © 2024 {/* {ProductName}*/} Rubiex Exchange. All Rights Reserved
          version 1.0.6
        </div>
        <div className="color_box">
          <div className="collapse" id="color_button">
            <div className="color_box_in">
              <button
                data-toggle="collapse"
                className="color_button collapsed"
                data-target="#color_button"
              >
                {" "}
                <i className="fa fa-cog"></i>
              </button>
              <ul className="color-skins">
                <li>
                  <a
                    className="theme-skin skin-1"
                    href="#"
                    title="default Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-2"
                    href="#"
                    title="pink Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-3"
                    href="#"
                    title="sky Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-4"
                    href="#"
                    title="green Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-5"
                    href="#"
                    title="red Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-6"
                    href="#"
                    title="orange Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-7"
                    href="#"
                    title="purple Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-8"
                    href="#"
                    title="blue Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-9"
                    href="#"
                    title="gray Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-10"
                    href="#"
                    title="brown Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-11"
                    href="#"
                    title="gray Theme"
                  ></a>
                </li>
                <li>
                  <a
                    className="theme-skin skin-12"
                    href="#"
                    title="golden Theme"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
