export const MobileValidation = (name, value, page) => {
    var value = value
    var error = ""
    if (!value) {
        error = "This field is required"
        return error
    }
       // var regexNum = /^([0|\+[0-9]{1,10})?([0-9]{5})$/;
       var regexNum=/^[6-9]\d{9}$/
       if (!value.match(regexNum)){
           error = " Please enter a valid mobile number."
           return error
       }
    if(page == "Register" && value.length !== 10){
        error = " Please enter a valid mobile number."
        return error
    }
 
    return error
}