import React from "react";
import icon1 from "../../../img/icon1.png";
import icon1_h from "../../../img/icon1-h.png";
import icon2 from "../../../img/icon2.png";
import icon2_h from "../../../img/icon2-h.png";
import icon3 from "../../../img/icon3.png";
import icon3_h from "../../../img/icon3-h.png";

class Testimonial extends React.Component {
  render() {
    return (
      <div className="how-main-outer p60 ">
         <div className="container">
    <div className="how-outer">
      <div className="row align-items-center">
        <div className="col-12 col-xl-4">
          <div className="section-heading ">
            <h6>RUBIEX</h6>
            <h2>Simple Steps to Start Crypto Trading With RUBIEX</h2>
            <p>Getting started with Rubiex is quick, easy, and secure. Follow these three simple steps to begin your cryptocurrency trading journey today:</p>
            <a href="#" className="btn btn_man mt-md-4">
              Start Trading
            </a>
          </div>
        </div>
        <div className="col-12 col-xl-8">
          <div className="row">
            <div className="col-md-6 col-12  mt-md-4">
              <div className="how-inner ">
                <div className="img-outer">
                  <i className="fa fa-user" />
                </div>
                <div className="content-outer">
                  <h3>Sign-Up/Registration</h3>
                  <p>
                    Create your account on our crypto exchange platform to start
                    crypto trading.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-4">
              <div className="how-inner ">
                <div className="img-outer">
                  <i className="fa fa-university" />
                </div>
                <div className="content-outer">
                  <h3>Add &amp; Verify Your Bank</h3>
                  <p>
                    In this step, you will be completing your KYC process to add
                    your bank details.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-4  mt-md-4">
              <div className="how-inner ">
                <div className="img-outer">
                  <i className="fa fa-usd" />
                </div>
                <div className="content-outer">
                  <h3>Add Fund in Your Wallet</h3>
                  <p>
                    Now add funds to your wallet to take one more step towards
                    starting crypto trading.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="how-inner ">
                <div className="img-outer">
                  <i className="fa fa-signal" />
                </div>
                <div className="content-outer">
                  <h3>Enjoy Crypto Trading</h3>
                  <p>
                    Now buy, sell or transfer the cryptocurrencies as per your
                    choice and current market trends.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        {/* <div className="container">
           <div className="section-heading mb-md-4">
				 <h2>Start Trading in Just 3 Simple Steps</h2>
				<p>Getting started with Rubiex is quick, easy, and secure. Follow these three simple steps to begin your cryptocurrency trading journey today:</p>
			   </div>
          <div className="how-outer">
		   <div className="row"> 

              <div className="col-md-4 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/app03r34.png" alt="" className="img-fluid" />
                  </div>
                  <div className="content-outer"> 
                    <h3><span>Step 1</span>Sign Up and Verify Your Account</h3>
                    <p>Creating your Rubiex account takes only a few minutes!</p>
                    <ul>
                       <li>Sign up with your email address and create a strong password.</li>
                       <li>Set up Two-Factor Authentication (2FA) for enhanced security.</li>
                       <li>Complete a quick identity verification process to unlock all trading features.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/cryptomining-hit.png" alt="" className="img-fluid"  />
                  </div>
                  <div className="content-outer"> 
                    <h3><span>Step 2</span> Fund Your Account</h3>
                    <p>Choose your preferred deposit method and fund your account in minutes.</p>
                    <ul>
                      <li>Deposit funds via bank transfer, credit card, or other crypto wallets.</li>
                      <li>Rubiex supports multiple currencies, giving you flexibility in funding your account.</li>
                      <li> Your funds will be safely stored and ready for trading in no time.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-4 ">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/crypto-wallet-hit.png" alt="" className="img-fluid"  />
                  </div>
                  <div className="content-outer"> 
                    <h3><span>Step 3</span>Add Fund in Wallet</h3>
                    <p>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
                    <ul>
                      <li>Access real-time charts and market data for informed trading decisions.</li>
                      <li>  Place market or limit orders with ease on our user-friendly platform. </li>
                      <li>Trade top cryptocurrencies like Bitcoin, Ethereum, and more with lightning-fast execution and low fees.</li> 
                    </ul>
                  </div>
                </div>
              </div> 
            </div>
           </div>
          </div> */}
          </div>
    );
  }
}

export default Testimonial;
