import React from "react";
import Chart from "react-apexcharts";

const CryptoChart = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'area',
      sparkline: {
        enabled: true, // Minimal chart without axes
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    tooltip: {
      enabled: false,
    },
    // colors: ['#00E396'],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    },
  };

  const chartData = [
    {
      name: "Price",
      data: data,
    },
  ];

  return (
    <div className="chart">
      <Chart options={chartOptions} series={chartData} type="area" height={50} />
    </div>
  );
};

export default CryptoChart;
