import * as dataUrl from "./DatUrl";
import * as crud from "./OpsServies";

const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};
const token = localStorage.getItem("token");

const whiteListData = async (walletAddress) => {
    let whiteListData = await crud.post(dataUrl.mainUrl + dataUrl.base.whitelistData, walletAddress, token)
    return whiteListData
}
const whiteListAdd = async (data,token) => {
    let whiteListAdd = await crud.post(dataUrl.mainUrl + dataUrl.base.whitelistAdd, data, token)
    return whiteListAdd
}
const cryptoCoinList = async (data) => {
    let cryptoCoinList = await crud.get(dataUrl.mainUrl + dataUrl.base.cryptoCoinList, data, token)
    return cryptoCoinList
}

const userData = async (data) => {
    let userData = await crud.get(dataUrl.mainUrl + dataUrl.base.userData, data, token)
    return userData
}
const deleteWhiteList = async (data) => {
    let deleteWhiteList = await crud.post(dataUrl.mainUrl + dataUrl.base.deleteWhiteList, data, token)
    return deleteWhiteList
}

export { whiteListData, whiteListAdd, cryptoCoinList, userData, deleteWhiteList }