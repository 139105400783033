import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import DocumentMeta from "react-document-meta";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExchangeHeader from "../Common/ExchangeHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import { Modal, Button } from "react-bootstrap";
import LeftTab from "../Common/LeftTab";
const baseUrl = myConstList.baseUrl;

const meta = {};

export class Refer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReferCode: "",
      isDepositModalOpen: false,
      referList: [],
      adminbankdetails: [],
      referUrl: "",
    };
    this.adminbankinfo();
  }

  componentWillMount() {
    this.GetUserData();
  }

  copyCodeToClipboard = () => {
    const el = this.Copy;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess: true });
  };
  copyCodeToClipboard1 = () => {
    const el = this.Copy1;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess1: true });
  };

  GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      this.setState({ ReferCode: resp.data[0].refer_id });
      this.GetReferList(resp.data[0].refer_id);
      this.setState({
        referUrl: `https://rubiex.io/register-for-bitcoin-exchange/${resp.data[0].refer_id}`,
        // referUrl: `http://192.168.1.41:3000/register-for-bitcoin-exchange/${resp.data[0].refer_id}`,
      });
    });
  };
  changeTheme = (e) => {
    // this.setState({graphColor:e},()=>{
    //   this.graphOnClick()
    // })
  };

  GetReferList = (ReferCode) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let data = {
      refer_id: ReferCode,
    };
    // refer-list
    axios
      .post(baseUrl + "/frontapi/user-refer-history", data, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ referList: resp.data, referStatus: resp.status });
      });
    // axios.get(baseUrl + "/frontapi/refer-prices", data).then((resp) => {
    //   var resp = resp.data;
    //   this.setState({
    //     monthly: resp.data[0].monthly,
    //     quarterly: resp.data[0].quarterly,
    //     half_yearly: resp.data[0].half_yearly,
    //     yearly: resp.data[0].yearly,
    //   });
    // });
    // axios.post(baseUrl + "/frontapi/refer-data", {}, config).then((resp) => {
    //   var resp = resp.data;
    //   if (resp.status == true) {
    //     this.setState({
    //       isActive: resp.data[0].is_active,
    //       isApproved: resp.data[0].is_approved,
    //       plan: resp.data[0].plan,
    //       startDate: resp.data[0].start_date,
    //       endDate: resp.data[0].end_date,
    //       status: resp.data[0].is_approved,
    //     });
    //   }
    // });
  };

  showReferListHtml = () => {
    if (this.state.referStatus) {
      var html = [];
      this.state.referList.forEach((item, s) => {
        html.push(
          <tr>
            <td>{s + 1}</td>
            <td>{item.email}</td>
            <td>{item.coin_amount}</td>
            <td>{Moment.utc(item.created).local().format("lll")}</td>
          </tr>
        );
      });
      return html;
    }
  };

  adminbankinfo = () => {
    axios.get(baseUrl + "/frontapi/adminbankinfo").then((resp) => {
      var resp = resp.data;
      if (resp.status == true) {
        this.setState({ adminbankdetails: resp.data[0] });
      }
    });
  };

  depositRefer = (value) => {
    this.setState({ isDepositModalOpen: true });
    if (value == "monthly") {
      this.setState({ inr_amount: this.state.monthly, plan: "monthly" });
    }
    if (value == "quarterly") {
      this.setState({ inr_amount: this.state.quarterly, plan: "quarterly" });
    }
    if (value == "half_yearly") {
      this.setState({
        inr_amount: this.state.half_yearly,
        plan: "half_yearly",
      });
    }
    if (value == "yearly") {
      this.setState({ inr_amount: this.state.yearly, plan: "yearly" });
    }
  };

  refrerDeposit = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    var formData = new FormData();
    formData.append("tx_image", this.state.IdentityPic3);
    formData.append("utrId", this.state.trNumber);
    formData.append("amount", this.state.inr_amount);
    formData.append("plan", this.state.plan);
    axios
      .post(baseUrl + "/frontapi/refer-deposit", formData, config)
      .then((resp) => {
        var resp = resp.data;
        if (resp.status == true) {
          this.setState({ isDepositModalOpen: false, trNumber: "" });
          toast.success(resp.message);
        }
        if (resp.status == false) {
          toast.error(resp.message);
        }
      });
  };

  closeOpenINRDepositModal = () => {
    this.setState({
      isDepositModalOpen: false,
      trNumber: "",
      inr_amount: "",
    });
  };

  onChangeImage3 = (e) => {
    e.preventDefault();
    var IdentityPic3 = e.target.files[0];
    this.setState({ IdentityPic3: e.target.files[0] });
    this.setState({ userUploadImage3: URL.createObjectURL(IdentityPic3) });
    if (IdentityPic3 === false) {
      this.setState({ userUploadImage3: false });
      toast.error("Please select valid image jpg|jpeg|png|gif.");
      return false;
    }
    if (!IdentityPic3.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ userUploadImage3: false });
      toast.error("Please select valid image jpg|jpeg|png|gif.");
      return false;
    }
  };

  handleInrChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="">
        <DocumentMeta {...meta}>
          <ExchangeHeader graphColor={this.changeTheme} />
          <div className="new_inpage">
            <div className="container container2">
              <LeftTab />

              <div className="refer-main-outer">
                <div className="row">
                  <div className="col-md-4">
                    <h5>Refer & Earn</h5>
                    <div className="refer-left-outer white_box_refer">
                      <div className="refer-img-outer text-center mb-4">
                        <img
                          className="img-fluid"
                          src="/assets/img/refer.png"
                        />
                      </div>
                      <h6>
                        Get 100 USDT tokens on referring this platform to your
                        friends.
                      </h6>
                      <label className="form-label">Referral Code</label>
                      <div className="form-group position-relative">
                        <input
                          className="form-control fs12"
                          type="textarea"
                          ref={(textarea) => (this.Copy = textarea)}
                          readonly=""
                          name="wallet_address"
                          value={this.state.ReferCode}
                        />
                        {this.state.copySuccess ? (
                          <div style={{ color: "#fc8752", fontWeight: 600 }}>
                            Referral code copied
                          </div>
                        ) : null}
                        <button
                          type="button"
                          className="btn btn_man position-absolute copy_button"
                          onClick={() => this.copyCodeToClipboard()}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </button>
                      </div>
                      <label className="form-label">Referral Url</label>
                      <div className="form-group position-relative">
                        <input
                          className="form-control fs12"
                          type="textarea"
                          ref={(textarea) => (this.Copy1 = textarea)}
                          readonly=""
                          name="wallet_address"
                          value={this.state.referUrl}
                        />
                        {this.state.copySuccess1 ? (
                          <div style={{ color: "#fc8752", fontWeight: 600 }}>
                            Referral Url
                          </div>
                        ) : null}
                        <button
                          type="button"
                          className="btn btn_man position-absolute copy_button"
                          onClick={() => this.copyCodeToClipboard1()}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-8">
                    <h5>Refer History</h5>
                    <div className="refer-history-outer white_box_refer">
                      <div className="refer-right-outer">
                        <table className="table w-100 text-left refer-history">
                          <tr>
                            <th>S. No.</th>
                            <th>Refer To</th>
                            <th>Amount (AFT)</th>
                            <th>Date</th>
                          </tr>
                          {this.showReferListHtml()}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Deposit INR Modal */}
              <Modal
                show={this.state.isDepositModalOpen}
                onHide={this.closeOpenINRDepositModal}
                className="account-popup-outer"
              >
                <Modal.Header closeButton>
                  <Modal.Title> Deposite INR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <input
                      type="number"
                      // value="0"
                      className="form-control"
                      name="inr_amount"
                      disabled
                      value={this.state.inr_amount}
                    />
                  </div>
                  <div className="form-group">
                    <label>Upload Image:</label>
                    <input
                      className="form-control"
                      type="file"
                      id="file-upload3"
                      name="IdentityProof3"
                      onChange={this.onChangeImage3}
                    />
                  </div>
                  <div className="form-group">
                    <label>UTR/TID:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Utr number / Transaction number"
                      name="trNumber"
                      value={this.state.trNumber}
                      onChange={this.handleInrChange}
                    />
                  </div>
                  <div className="min-max-amount">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="bank-detail bank-left">
                          <h5>Bank Details</h5>
                          <div className="bank-detail-outer border-right">
                            <p>
                              Bank Name :{" "}
                              {this.state.adminbankdetails
                                ? this.state.adminbankdetails.bankname
                                : ""}{" "}
                              <a href="javascript:void(0)">
                                <i
                                  className="fa fa-copy"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.adminbankdetails.bankname
                                    );
                                    toast.success("Copied");
                                  }}
                                ></i>
                              </a>
                            </p>
                            <p>
                              Account Number :{" "}
                              {this.state.adminbankdetails
                                ? this.state.adminbankdetails.accountnumber
                                : ""}{" "}
                              <a href="javascript:void(0)">
                                <i
                                  className="fa fa-copy"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.adminbankdetails.accountnumber
                                    );
                                    toast.success("Copied");
                                  }}
                                ></i>
                              </a>
                            </p>
                            <p>
                              IFSC Code :{" "}
                              {this.state.adminbankdetails
                                ? this.state.adminbankdetails.ifsccode
                                : ""}{" "}
                              <a href="javascript:void(0)">
                                <i
                                  className="fa fa-copy"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.adminbankdetails.ifsccode
                                    );
                                    toast.success("Copied");
                                  }}
                                ></i>
                              </a>
                            </p>
                            <p>
                              Holder Name :{" "}
                              {this.state.adminbankdetails
                                ? this.state.adminbankdetails.accountholder
                                : ""}{" "}
                              <a href="javascript:void(0)">
                                <i
                                  className="fa fa-copy"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.adminbankdetails.accountholder
                                    );
                                    toast.success("Copied");
                                  }}
                                ></i>
                              </a>
                            </p>
                            <p>
                              Branch Name :{" "}
                              {this.state.adminbankdetails
                                ? this.state.adminbankdetails.branchname
                                : ""}{" "}
                              <a href="javascript:void(0)">
                                <i
                                  className="fa fa-copy"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.adminbankdetails.branchname
                                    );
                                    toast.success("Copied");
                                  }}
                                ></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bank-detail bank-right-outer">
                          <h5>Upi</h5>
                          <p className="upi-id tc">
                            {this.state.adminbankdetails
                              ? this.state.adminbankdetails.upi_id
                              : ""}{" "}
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.upi_id
                                );
                                toast.success("Copied");
                              }}
                            >
                              <i className="fa fa-copy"></i>
                            </a>
                          </p>
                          <div className="qr-img-outer">
                            <img
                              src={
                                baseUrl +
                                "/static/bankImage/" +
                                this.state.adminbankdetails.upi_image
                              }
                              alt="banner"
                              className="img-thumbnail"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="deposit-amount-btn">
                    <button
                      className="btn btn_man fs14 m0a"
                      onClick={this.refrerDeposit}
                    >
                      Deposit Amount
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <ToastContainer />
          </div>
          <Footer />
        </DocumentMeta>
      </div>
    );
  }
}

export default Refer;
