import React from "react";

let error = ""

export const ImageValidation = (name,value) => {
    const min = 10;
    let max 
    if(name=="Passport"){
        max = 1024;
    }
    else{
        max = 2000;
    }
    const capitalized = name.charAt(0).toUpperCase() + name.slice(1);

    if(!value){
       error = `This field is required`
       return error
    }
    if(value && value.name && !value.name.match(/\.(jpg|jpeg|png|gif)$/)){
        error = `Please select jpg, jpeg or png image format`
        return error
     }

    //  if (value.size / 1000 < min || value.size / 1024 > max) {
    //      if(name=="Passport"){

    //          error="Image size should be  10 kb to 1mb."
    //      }
    //      else{
    //         error="Image size should be  10 kb to 2mb."
    //      }
    //     return error;
    //   }
    // if(value.replace(/\s+/g, "").length == 0){
    //     error = `Please enter valid ${capitalized}`
    //     return error
    //  }
}
export const imageBase64 = (file,name,type)=>{
    var base64 = localStorage[file];
    var base64Parts = base64.split(",");
    var fileFormat = base64Parts[0].split(";")[1];
    var fileContent = base64Parts[1];
    var pancardImagefile = new File([fileContent],name, {type: type});
    return pancardImagefile
}